import React, { useEffect, useRef, useState } from "react";
import { Helmet } from 'react-helmet';
import { isAfter, isBefore, parseISO } from 'date-fns';
import { useParams, useHistory, useLocation } from "react-router-dom";
import Footer from "../../components/website/Footer";
import Header from "../../components/website/Header";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleDown, faAngleDoubleUp } from '@fortawesome/free-solid-svg-icons';
import { PayPalScriptProvider, PayPalButtons} from "@paypal/react-paypal-js";
import $ from "jquery";
import "magnific-popup";
import "swiper/css";
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination } from 'swiper/core';
SwiperCore.use([Navigation, Pagination]);
import 'swiper/css/pagination';
import api from "../../api/endpoints/events.js";
import storage from "../../firebase";
import InvoicePDF from "../../components/InvoicePDF";
import { NotificationManager } from 'react-notifications';
import { ThreeDots } from "react-loader-spinner";
import NotFound from "./NotFound";
import getPartnerUrl from "../../components/getPartnerUrl";
import PhoneInput from 'react-phone-number-input';
import DiscountBanner from '../../components/DiscountBanner';
import 'react-phone-number-input/style.css';
import {
  Megaphone,
  Users,
  BookOpen,
  MessageCircle,
  Lightbulb,
  Heart
} from 'lucide-react';
import {
  listAll,
  ref as storageRef,
  getDownloadURL,
} from "firebase/storage";
import axios from "axios";
import keyImage1 from "../../assets/images/key1.png";
import keyImage2 from "../../assets/images/key2.png";
import keyImage3 from "../../assets/images/key3.png";
import keyImage4 from "../../assets/images/key4.png";
import keyImage5 from "../../assets/images/key5.png";
import keyImage6 from "../../assets/images/key6.png";

// import Rodal from "rodal";
import Modal from "../../components/Modal";

// include styles
import "rodal/lib/rodal.css";

// Import Swiper styles
import "swiper/css";

import { register } from "swiper/element/bundle";
import Breadcrumbs from "../../components/Breadcrumbs";
// import Timer from "../../components/Timer";
register();

const eventSettings = {
  eventEL: [
    { name: 'SUPER', startDate: '2024-01-01', endDate: '2024-06-01' },
    { name: 'BIRD', startDate: '2024-06-02', endDate: '2024-07-01' },
    { name: 'STANDARD', startDate: '2024-07-02', endDate: '2024-09-01' },
    { name: 'LAST', startDate: '2024-09-02', endDate: '2024-09-25' },
  ],
  eventAutomotive: [
    { name: 'BIRD', startDate: '2024-01-01', endDate: '2024-09-01' },
    { name: 'STANDARD', startDate: '2024-09-02', endDate: '2024-10-01' },
    { name: 'LAST', startDate: '2024-10-01', endDate: '2024-10-09' },
  ],
  eventLogistics: [
    { name: 'Pre-Registration', startDate: '2024-01-01', endDate: '2024-09-01' },
    { name: 'Bird', startDate: '2024-09-02', endDate: '2024-10-01' },
    { name: 'Standart', startDate: '2024-10-02', endDate: '2025-10-09' },
  ],
  default: 'Register',
};
const EventFeatures = ({ features }) => {
  return (
      <div className="tw-w-full tw-px-0 sm:tw-px-4">
        <div className="tw-w-[300px] sm:tw-container sm:tw-max-w-5xl tw-mx-auto">
          <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 lg:tw-grid-cols-3 tw-gap-x-3 sm:tw-gap-x-8 tw-gap-y-6 sm:tw-gap-y-16">
            {features.map((feature, index) => (
                <EventFeature key={index} {...feature} />
            ))}
          </div>
        </div>
      </div>
  );
};

const EventFeature = ({ icon, title, description }) => (
    <div className="tw-flex tw-flex-col tw-items-start tw-mb-4 sm:tw-mb-8 tw-px-2 sm:tw-px-0">
      <div className="tw-flex tw-items-center tw-justify-center tw-bg-[#D85121] tw-w-10 sm:tw-w-12 tw-h-10 sm:tw-h-12 tw-mb-2 sm:tw-mb-4">
        {icon}
      </div>
      <h3 className="tw-text-base sm:tw-text-lg md:tw-text-xl tw-font-bold tw-mb-1 sm:tw-mb-2">
        {title}
      </h3>
      <p className="tw-text-xs sm:tw-text-sm md:tw-text-base tw-text-gray-700">
        {description}
      </p>
    </div>
);
// const myEventList = ["7TH ANNUAL INHALATION & RESPIRATORY DRUG DELIVERY CONFERENCE"];
const myEventListButtons = ["5TH ANNUAL ADVANCED PLASTICS RECYCLING HYBRID CONFERENCE",
  "3RD ANNUAL NURSE MANAGEMENT & PERSPECTIVES ON NURSING LEADERSHIP HYBRID CONFERENCE",
  "ADVANCED PLASTICS RECYCLING CONFERENCE AND CHRISTMAS INDUSTRY CELEBRATION", "5TH ANNUAL BTEL SUMMIT"];



const myEventVideo = ["6TH ANNUAL BTEL SUMMIT & EXHIBITION"];
const eventOnline = ["INNOVATIONS IN PLANT-BASED FOOD AND BEVERAGE SUMMIT"]

const myEventDescription = ["5TH ANNUAL EXECUTIVE & PERSONAL ASSISTANT CONFERENCE"]

const eventBTEL = ["6TH ANNUAL BTEL SUMMIT & EXHIBITION"]
const eventPlantBased = ["INNOVATIONS IN PLANT-BASED FOOD AND BEVERAGE SUMMIT"]
const eventNurse = ["4TH ANNUAL NURSE MANAGEMENT & PERSPECTIVES ON NURSING LEADERSHIP CONFERENCE"]
const eventEL = ["4TH ANNUAL EXRACTABLE AND LEACHABLE ONLINE CONFERENCE"]
const eventCleaning = ["THE 6TH ANNUAL SUSTAINABLE CLEANING PRODUCTS & FORMULATION HYBRID CONFERENCE", "5TH ANNUAL BTEL SUMMIT & EXHIBITION"]

const eventOnlinePa = ["EXECUTIVE AND PERSONAL ASSISTANT ONLINE CONFERENCE"]

const  eventInhalation = ["7TH ANNUAL INHALATION & RESPIRATORY DRUG DELIVERY CONFERENCE AND CHRISTMAS INDUSTRY CELEBRATION"]

const eventPA = ["5TH ANNUAL EXECUTIVE & PERSONAL ASSISTANT SUMMIT"]
const eventAdvancedPlastic = ["ADVANCED PLASTICS RECYCLING CONFERENCE AND CHRISTMAS INDUSTRY CELEBRATION"]

const eventLogistics = ["LOGISTICS TECH SUMMIT"]
const eventFAMS = ["FUTURE AUTOMOTIVE MATERIALS SUMMIT"]
const eventGEN = ["3RD ANNUAL GENOTOXIC IMPURITIES SUMMIT: BEYOND NITROSAMINES"];
const eventAutomotive = ["AUTOMOTIVE DISPLAY TECHNOLOGIES & HMI 2024 ONLINE CONFERENCE"];
const eventIndustrialWater = ["INDUSTRIAL WATER & EFFLUENT MANAGEMENT ONLINE CONFERENCE"]

const galleryCreator = ["6TH ANNUAL BTEL SUMMIT & EXHIBITION", "ADVANCED PLASTICS RECYCLING CONFERENCE AND CHRISTMAS INDUSTRY CELEBRATION",
  "3RD ANNUAL GENOTOXIC IMPURITIES SUMMIT: BEYOND NITROSAMINES", "2ND ANNUAL FUTURE LAWTECH CONFERENCE", "EXECUTIVE AND PERSONAL ASSISTANT ONLINE CONFERENCE",
  "LOGISTICS TECH SUMMIT", "6TH ANNUAL B.E.S.T. ASSISTANTS SUMMIT", "FUTURELAB INNOVATORS SUMMIT"]


const eventSweet = ["SWEETNESS REIMAGINED CONFERENCE"]


const allEvents = [""];



const speakerNamesToInclude = ["David Svoboda"];
function SingleConference() {
  // eslint-disable-next-line no-unused-vars
  const [buttonTexts, setButtonTexts] = useState({});
  const [isProcessing, setIsProcessing] = useState(false);
  const [sortedPaymentsTwo, setSortedPayments] = useState([]);
  const [sortedPaymentsForPayPal, setSortedPaymentsForPayPal] = useState([]);


  const swiperRef = useRef(null);
  let location = useLocation();

  const createModalLink = (modalType) => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('modal', modalType);
    return `${location.pathname}?${searchParams.toString()}`;
  };

  const marqueeRef = useRef();



  const handleImageClick = (index) => {
    console.log('Clicked on image with index:', index);
    const mainSwiper = document.getElementById('mainSwiper');
    if (mainSwiper) {
      mainSwiper.swiper.slideTo(index);
    }};

  const handleImageClickhotel = (index) => {
    console.log('Clicked on image with index:', index);
    const hotelSwiper = document.getElementById('hotelSwiper');
    if (hotelSwiper) {
      hotelSwiper.swiper.slideTo(index);
    }};
  const handleImageClickpartner = (index) => {
    console.log('Clicked on image with index:', index);
    const partnersSwiper = document.getElementById('partnersSwiper');
    if (partnersSwiper) {
      partnersSwiper.swiper.slideTo(index);
    }};

  const { id } = useParams();
  const [conference, setConference] = useState([]);
  const [galleryImages, setGalleryImages] = useState([]);
  const [hotelImages, setHotelsImages] = useState([]);
  const [showAll, setShowAll] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [activeSlide, setActiveSlide] = useState(0);
  const [isValidId, setIsValidId] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [delegatePopupOpened, setDelegatePopupOpened] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  // const [isBankTransferAvailable, setIsBankTransferAvailable] = useState(false);
  const [isPayPalDataFormVisible, setIsPayPalDataFormVisible] = useState(true);
  const [isCreditCardFormValid, setIsCreditCardFormValid] = useState(false);

  const [isFormValid, setIsFormValid] = useState(false);

  const agendaInitState = {
    conferenceName: "",
    fullName: "",
    email: "",
    phone: "",
    job: "",
    company: "",
    howHeard: "",
  };

  const bankInitState = {
    invoiceNum: "DEFAULT_NUMBER",
    conferenceName: "",
    firstName: "",
    lastName: "",
    email: "",
    job: "",
    company: "",
    address: "",
    city: "",
    vat: "",
    phone: "",
    ticket: { name: "", price: "" },
    promo: "",
  };

  const payPalInitState = {
    invoiceNum: "DEFAULT_NUMBER",
    conferenceName: "",
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    job: "",
    company: "",
  };

  const creditCardInitState = {
    invoiceNum: "DEFAULT_NUMBER",
    conferenceName: "",
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    job: "",
    company: "",
  };

  const [agenda, setAgenda] = useState(agendaInitState);
  const [bank, setBank] = useState(bankInitState);
  const [payPal, setPayPal] = useState(payPalInitState);
  const [creditCard, setCreditCard] = useState(creditCardInitState);
  const [currentType, setCurrentType] = useState(null);

  const handleAgenda = (event) => {
    const { name, value } = event.target;
    setAgenda((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleBank = (event) => {
    const { name, value } = event.target;
    const [paymentName, price] = value.split(':');
    setBank((prevState) => ({
      ...prevState,
      [name]: value,
      conferenceName: conference.eventName,
      ticket: { name: paymentName, price },
    }));
  };

  // const handleCreditCard = (event) => {
  //   const { name, value } = event.target;
  //   setCreditCard((prevState) => ({
  //     ...prevState,
  //     [name]: value,
  //     conferenceName: conference.eventName,
  //     from: "stripe btel"
  //   }));
  //
  //   validatePayPalForm({
  //     ...payPal,
  //     [name]: value,
  //   });
  // };






  const handlePayPal = (event) => {
    const { name, value } = event.target;
    setPayPal((prevState) => ({
      ...prevState,
      [name]: value,
      conferenceName: conference.eventName,
      from: "paypal btel"
    }));

    validatePayPalForm({
      ...payPal,
      [name]: value,
    });
  };

  const validatePayPalForm = (data) => {
    const { firstName, lastName, email, job, company } = data;
    const isValid =
        firstName.trim() !== '' &&
        lastName.trim() !== '' &&
        email.trim() !== '' &&
        job.trim() !== '' &&
        company.trim() !== '';
    setIsFormValid(isValid);
  };


  const handlePhoneChange = (phoneValue) => {
    setBank((prevState) => ({
      ...prevState,
      phone: phoneValue,
    }));
  };

  const handleAgendaPhoneChange = (phoneValue) => {
    setAgenda((prevState) => ({
      ...prevState,
      phone: phoneValue,
    }));
  };


  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  }



  function handleSubmit(e) {
    e.preventDefault(); // Предотвращаем стандартное поведение формы (перезагрузку страницы)

    // Выполняем логику обработки данных формы
    // Например, отправка данных на сервер или другие действия

    // После обработки данных, вы можете перенаправить пользователя или выполнить другие действия
  }

  const handlePayPalSubmit = async (e) => {
    e.preventDefault();
    if (isProcessing) return; // Защита от множественных нажатий

    try {
      setIsProcessing(true);
      await postMessageToSlack(e, "pay-pal");
      setIsPayPalDataFormVisible(false);
    } finally {
      setIsProcessing(false);
    }
  };

  const handleCreditCardSubmit = async (e) => {
    e.preventDefault();
    if (isProcessing) return;

    try {
      setIsProcessing(true);
      await postMessageToSlack(e, "credit-card");
      window.location.href = `/stripe/${conference.slug}`;
    } finally {
      setIsProcessing(false);
    }
  };

  function handleFormSubmit(e) {
    e.preventDefault(); // Предотвращаем стандартное поведение формы (перезагрузку страницы)

    // Выполняем логику отправки сообщения в Slack
    postMessageToSlack(e, "agenda");

    // Выполняем логику обработки данных формы или другие действия
    handleSubmit(e);
  }
  const keyImages = [
    keyImage1,
    keyImage2,
    keyImage3,
    keyImage4,
    keyImage5,
    keyImage6,
  ];

  const createOrder = (data, actions, price) => {
    return actions.order.create({
      purchase_units: [
        {
          amount: {
            value: price, // Use the passed price value
          },
        },
      ],
    });
  };

  const onApprove = (data, actions) => {
    // This function is called when the payment is approved
    return actions.order.capture().then(details => {
      console.log('Payment successful', details);
    });
  };

  const imageCache = new Map();

  const fetchImages = async (listRef) => {
    try {
      if (imageCache.has(listRef)) {
        return imageCache.get(listRef);
      }
      const result = await listAll(listRef);
      const urlPromises = result.items.map(async (imageRef) => {
        const imageUrl = await getDownloadURL(imageRef);
        return imageUrl;
      });

      const urls = await Promise.all(urlPromises);
      imageCache.set(listRef, urls);
      return urls;
    } catch (error) {
      console.log('Error fetching images: ', error);
      throw error;
    }
  };

  const generateInvoice = async (bank, invoiceNum) => {
    try {
      console.log('bank before generate pdf: ', bank);
      const pdfBlob = await InvoicePDF(bank, invoiceNum);
      return pdfBlob; // Return the FormData containing the PDF
    } catch (error) {
      console.error('Error generating invoice:', error);
      throw error; // Re-throw the error to be handled in the higher-level function
    }
  };



  const sendToAPI = async (type, url) => {

    let bankName = {
      "Conference": bank.conferenceName,
      "First Name": bank.firstName,
      "Last Name": bank.lastName,
      "Email": bank.email,
      "Job Title": bank.job,
      "Company": bank.company,
      "Company Address": bank.address,
      "City": bank.city,
      "VAT": bank.vat,
      "Phone": bank.phone,
      "Ticket Type": { "Ticket Name": bank.ticket.name, "Ticket Price": bank.ticket.price },
      "Promo": bank.promo
    }

    const invoiceNumber = await api.get(`${url}User/invoice/get-number`);
    const inNum = invoiceNumber.data.toString();
    console.log('invoice number: ', inNum);
    setBank((prevState) => ({
      ...prevState,
      invoiceNum: inNum,
    }));

    setIsLoading(true);

    try {
      const pdfBlob = await generateInvoice(bank, inNum);
      const formData = new FormData();
      formData.append('data', JSON.stringify(bankName));
      formData.append('pdfFile', pdfBlob, 'invoice.pdf');

      console.log('bankData is: ', bankName);

      const response = await axios.post(`${url}User/slack-pdf`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      console.log('response from endpoint:', response);
      setIsModalOpen(false);
      document.querySelector(".header").style.zIndex = "3";
      if(type === "agenda") {
        NotificationManager.success('Thank you for your request!\n', 'Success', 5000);
      }
      else {
        NotificationManager.success('Thank you for your registration!\n' +
            'The invoice has been sent to your email.', 'Success', 5000);
      }
      setIsLoading(false);
    } catch (error) {
      console.error('Error sending request to API:', error);
      setIsLoading(false);
      // Handle error as needed
    }
  };

  const sendPayPalToAPI = async (type, url) => {
    let payPalData = {
      "From": "paypal btel",
      "Conference": payPal.conferenceName,
      "First Name": payPal.firstName,
      "Last Name": payPal.lastName,
      "Email": payPal.email,
      "Phone": payPal.phone,
      "Job Title": payPal.job,
      "Company": payPal.company,
      "Promo": payPal.promo
    };

    try {
      const response = await axios.post(`${url}User/slack/${type}`, payPalData);
      console.log('PayPal data sent:', response);
    } catch (error) {
      console.error("Error sending PayPal data to Slack:", error);
    }
  };



  const sendCreditCardToAPI = async (type, url) => {
    let creditCardData = {
      "From": "stripe btel", // Указываем источник данных
      "Conference": creditCard.conferenceName,
      "First Name": creditCard.firstName,
      "Last Name": creditCard.lastName,
      "Email": creditCard.email,
      "Phone": creditCard.phone,
      "Job Title": creditCard.job,
      "Company": creditCard.company,
      "Promo": creditCard.promo
    };

    try {
      const response = await axios.post(`${url}User/slack/${type}`, creditCardData);
      console.log('Credit Card data sent:', response);
    } catch (error) {
      console.error("Error sending Credit Card data to Slack:", error);
    }
  };


  const fetchPdf = async (eventId) => {
    try {
      const pdfRef = storageRef(storage, `gs://sns-cz.appspot.com/Agenda/${eventId}.pdf`);
      const pdfUrl = await fetchPdfUrl(pdfRef);
      return pdfUrl;
    } catch (error) {
      console.log('Error fetching PDF: ', error);
    }
  };

  const fetchPdfUrl = async (pdfRef) => {
    try {
      const pdfUrl = await getDownloadURL(pdfRef);
      return pdfUrl;
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    let isMounted = true; // Переменная для отслеживания монтирования компонента

    const fetchData = async () => {
      try {
        window.scrollTo(0, 0);

        const { data } = await api.get(`/Event/get-event-by-slug/${id}`);
        if (data.endDate == null) {
          if (isMounted) setIsValidId(false);
          return;
        }
        if (isMounted) {
          setConference(data);
          console.log('Conference id: ', data);

          setAgenda((prevState) => ({
            ...prevState,
            conferenceName: data.eventName,
          }));

          const conferenceGalleryListRef = storageRef(storage, `gs://sns-cz.appspot.com/Images/${data.id}`);
          const hotelId = data.hotels[0]?.id;
          const hotelGalleryListRef = storageRef(storage, `gs://sns-cz.appspot.com/Images/${hotelId}`);
          const [conferenceGalleryImages, hotelGalleryImages] = await Promise.all([
            fetchImages(conferenceGalleryListRef),
            fetchImages(hotelGalleryListRef),
          ]);

          // Установка изображений в состоянии
          setGalleryImages(conferenceGalleryImages);
          setHotelsImages(hotelGalleryImages);
          console.log('Gallery images: ', conferenceGalleryImages);
          console.log('Hotel images: ', hotelGalleryImages);
        }
      } catch (error) {
        console.log('Error fetching data: ', error);
        if (isMounted) setIsValidId(false);
      }
    };
    fetchData();

    return () => {
      isMounted = false; // Устанавливаем флаг в false при размонтировании
    };
  }, [id]);

  useEffect(() => {
    if (!conference) return;

    const newButtonTexts = {};
    const validPayments = [];
    const validPaymentsForPayPal = [];

    const getPaymentDateRange = (paymentName, conditions) => {
      for (const condition of conditions) {
        if (paymentName.includes(condition.name) && (!condition.exclude || !paymentName.includes(condition.exclude))) {
          return { startDate: parseISO(condition.startDate), endDate: parseISO(condition.endDate) };
        }
      }
      return null;
    };

    const applyEventSettings = (eventConditions) => {
      conference.payPalPayments?.forEach(payment => {
        const currentDate = new Date();
        const paymentDateRange = getPaymentDateRange(payment.paymentName, eventConditions);
        let text = eventSettings.default;

        if (paymentDateRange) {
          if (isAfter(currentDate, paymentDateRange.endDate)) {
            text = "Time Expired";
          } else if (isBefore(currentDate, paymentDateRange.startDate)) {
            text = "Coming Soon";
          } else {
            text = "Register";
          }
        }

        if (text === "Register" || text === "Coming Soon") {
          validPayments.push(payment);
          validPaymentsForPayPal.push(payment);
        }

        newButtonTexts[payment.paymentName] = text;
      });
    };

    if (eventEL.includes(conference.eventName)) {
      applyEventSettings(eventSettings.eventEL);
    } else if (eventAutomotive.includes(conference.eventName)) {
      applyEventSettings(eventSettings.eventAutomotive);
    } else if (eventLogistics.includes(conference.eventName)) {
      applyEventSettings(eventSettings.eventLogistics);
    } else {
      conference.payPalPayments?.forEach(payment => {
        newButtonTexts[payment.paymentName] = eventSettings.default;
        validPayments.push(payment);
        validPaymentsForPayPal.push(payment);
      });
    }

    setButtonTexts(newButtonTexts);
    setSortedPayments(validPayments);
    setSortedPaymentsForPayPal(validPaymentsForPayPal);
  }, [conference, eventEL, eventAutomotive, eventLogistics]);


  useEffect(() => {
    const marqueeElement = marqueeRef.current;

    if (marqueeElement) {
      const handleAnimationEnd = () => {
        // Когда анимация завершится, вы можете сделать что-то, если это необходимо
      };

      marqueeElement.addEventListener('animationend', handleAnimationEnd);

      // Очистка слушателя событий при размонтировании компонента
      return () => {
        marqueeElement.removeEventListener('animationend', handleAnimationEnd);
      };
    }

    return undefined;
  }, [marqueeRef]);

  function getSponsorType(sponsorType) {
    switch (sponsorType) {
      case 0:
        return <span>Diamond</span>;
      case 1:
        return <span>Platinum</span>;
      case 2:
        return <span>Gold</span>;
      case 3:
        return <span>Silver</span>;
      case 4:
        return <span>Keynote</span>;
      case 5:
        return <span>Exhibition</span>;
      case 6:
        return <span>Online Silver</span>;
      case 7:
        return <span>EXECUTIVE</span>;
        // Добавьте другие случаи по мере необходимости
      default:
        return <span>Другой тип спонсора</span>;
    }
  }
  useEffect(() => {
    $(".popup-with-zoom-anim").magnificPopup({
      type: "inline",
      fixedContentPos: false,
      fixedBgPos: true,
      overflowY: "auto",
      closeBtnInside: false,
      closeMarkup:
          '<button title="%title%" type="button" class="mfp-close close-button"> <div class="line1"></div> <div class="line2"></div> </button>',
      preloader: false,
      midClick: true,
      removalDelay: 300,
      autoOpen: false,
      mainClass: "my-mfp-fade-in",
      callbacks: {
        close: function () {
          $("html").removeClass("mfp-open");
        },
      },
    });
  });

  useEffect(() => {
    const overlay = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        if (mutation.type === 'childList') {
          const popUps = document.querySelector('.modal, .mfp-bg');
          if (popUps) {
          } else {
            document.body.style.overflow = 'auto';
          }
        }
      }
    });

    overlay.observe(document.body, { childList: true, subtree: true });

    return () => overlay.disconnect();
  }, []);

  const formateDate = (date) => {
    if (!date.startDate || !date.endDate) {
      return '';
    }

    const { startDate, endDate } = date;
    const start = new Date(startDate);
    const end = new Date(endDate);

    // Проверяем, совпадают ли даты (день, месяц и год)
    const isSameDate =
        start.getDate() === end.getDate() &&
        start.getMonth() === end.getMonth() &&
        start.getFullYear() === end.getFullYear();

    if (isSameDate) {
      return `${start.toLocaleString("en-US", { month: "long" })} ${start.getDate()}, ${start.getFullYear()}`;
    } else {
      const formattedStart = `${start.toLocaleString("en-US", { month: "long" })} ${start.getDate()}`;
      const formattedEnd = `${end.getDate()}, ${end.getFullYear()}`;
      return `${formattedStart} - ${formattedEnd}`;
    }
  };

  const formatDate = (date) => {
    const pad = (n) => (n < 10 ? '0' + n : n);
    return `${pad(date.getDate())}.${pad(date.getMonth() + 1)}.${date.getFullYear()} ${pad(date.getHours())}:${pad(date.getMinutes())}:${pad(date.getSeconds())}`;
  };


  // Функция для отправки данных в Google Sheets через Apps Script
  // Карта соответствий Conference и PID
  const projectIdMap = {
    "7TH ANNUAL INHALATION & RESPIRATORY DRUG DELIVERY CONFERENCE AND CHRISTMAS INDUSTRY CELEBRATION": "757288",
    "3RD ANNUAL GENOTOXIC IMPURITIES SUMMIT: BEYOND NITROSAMINES": "526657",
    "FUTURE AUTOMOTIVE MATERIALS SUMMIT": "895952",
    "4TH ANNUAL NURSE MANAGEMENT & PERSPECTIVES ON NURSING LEADERSHIP CONFERENCE": "254796",
    "ADVANCED PLASTICS RECYCLING CONFERENCE AND CHRISTMAS INDUSTRY CELEBRATION": "842423",
    "6TH ANNUAL BTEL SUMMIT & EXHIBITION": "773658",
    "INDUSTRIAL WATER & EFFLUENT MANAGEMENT ONLINE CONFERENCE": "994553",
    "2ND ANNUAL FUTURE LAWTECH CONFERENCE": "272835",
    "LOGISTICS TECH SUMMIT": "625869",
    "EXECUTIVE AND PERSONAL ASSISTANT ONLINE CONFERENCE": "446262",
    "6TH ANNUAL B.E.S.T. ASSISTANTS SUMMIT": "342898"
    // Добавьте новые проекты здесь
  };

// Функция для генерации RID
  const generateRID = () => {
    const array = new Uint8Array(4); // 4 байта = 32 бита
    crypto.getRandomValues(array);
    return Array.from(array, byte => byte.toString(16).padStart(2, '0')).join('');
  };

// Функция для отправки данных в Google Sheets
  const sendToGoogleSheets = async (data) => {
    const googleScriptUrl = "https://script.google.com/macros/s/AKfycbwjFACaBuTDJ-n8J00KibNAWwtn3MpEhJmOCFhVykoGmrWvtpYICE0tyClZbWZiEAvt/exec"; // Замените на ваш актуальный URL

    try {
      const formattedDate = formatDate(new Date());
      // Формируем параметры в формате x-www-form-urlencoded
      const params = new URLSearchParams({
        type: 'agenda', // Указываем тип данных
        date: formattedDate, // Обязательная метка дата
        pid: data.pid,
        rid: data.rid,
        Conference: data.conferenceName,
        "Full Name": data.fullName,
        Email: data.email,
        Phone: data.phone,
        "Job Title": data.job,
        Company: data.company,
        "How did you hear about us?": data.howHeard
      });

      console.log('Form-urlencoded body:', params.toString());

      const response = await fetch(googleScriptUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        },
        body: params.toString()
      });

      const result = await response.json();

      if (response.ok && result.status === "success") {
        console.log("Данные успешно добавлены в Google Sheets.");
      } else {
        console.error("Ошибка при добавлении данных в Google Sheets:", result.message || response.statusText);
      }
    } catch (error) {
      console.error("Ошибка при отправке данных в Google Sheets:", error);
    }
  };

// Основная функция отправки
  const postMessageToSlack = async (e, type) => {
    e.preventDefault();
    if (isProcessing) return; // Защита от множественных нажатий для всех типов

    let url = '';

    if (window.location.hostname === 'localhost') {
      url = 'https://api.bcf-events.com/';
    } else if (['green-desert-021865b10.3.azurestaticapps.net', 'brave-dune-00ae5aa10.3.azurestaticapps.net', 'new.bcf-events.com', 'bcf-events.com'].includes(window.location.hostname)) {
      url = 'https://api.bcf-events.com/';
    }

    try {
      setIsProcessing(true); // Устанавливаем флаг в начале для всех типов

      if (type === "agenda") {
        // Весь существующий код для agenda...
        const pid = projectIdMap[agenda.conferenceName] || "unknown_pid";
        const rid = generateRID();
        const date = new Date().toISOString().split('T')[0];

        let agendaName = {
          "Conference": agenda.conferenceName || "",
          "Full Name": agenda.fullName || "",
          "Email": agenda.email || "",
          "Phone": agenda.phone || "",
          "How did you hear about us?": agenda.howHeard || "",
          "Source": agenda.from || "regular_form"
        };

        if (window.location.href.includes('specific-conference/advanced-plastics-recycling-conference-and-christmas-industry-celebration')) {
          agendaName["Referer"] = "Рекламная ссылка";
        }

        let agendaData = {
          conferenceName: agenda.conferenceName || "",
          fullName: agenda.fullName || "",
          email: agenda.email || "",
          phone: agenda.phone || "",
          job: agenda.job || "",
          company: agenda.company || "",
          howHeard: agenda.howHeard || "",
          pid: pid,
          rid: rid,
          date: date
        };

        setAgenda(agendaInitState);

        const slackResponse = await axios.post(`${url}User/slack/${type}`, agendaName);
        await sendToGoogleSheets(agendaData);

        const anchor = document.createElement("a");
        const urlpdf = await fetchPdf(conference.id);
        anchor.href = urlpdf;
        anchor.download = "agenda.pdf";
        anchor.style.display = "none";
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);

      } else {
        await sendToAPI(type, url);
      }

      if (type === "pay-pal") {
        await sendPayPalToAPI(type, url);
      } else if (type === "credit-card") {
        await sendCreditCardToAPI(type, url);
      }

    } catch (error) {
      console.error("Ошибка при отправке сообщения в Slack:", error);
      NotificationManager.error('Error occurred. Please try again.');
    } finally {
      setIsProcessing(false); // Сбрасываем флаг в конце для всех типов
    }
  };




  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = (type, source = 'default') => {
    setCurrentType(type);
    setIsModalOpen(true);
    if (type === "delegate") {
      setDelegatePopupOpened(true);
      gtag('event', 'conversion', {
        'send_to': 'AW-16507053678/pcC6CKCyyZ4ZEO7Mlr89',
        'value': 1.0,
        'currency': 'CZK',
        'event_category': 'Modal Open',
        'event_label': 'Delegate Modal Opened'
      });
    }
    if (source === 'discount_banner') {
      setAgenda(prev => ({
        ...prev,
        from: 'discount_banner' // This will be included in the Slack message
      }));
    }
    document.querySelector(".header").style.zIndex = "1";
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    document.querySelector(".header").style.zIndex = "3";
    history.replace(location.pathname);
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const modalType = searchParams.get('modal');
    if (modalType) {
      handleOpenModal(modalType);
    } else {
      // Закрыть модальное окно, если параметр 'modal' удален из URL
      setIsModalOpen(false);
    }
  }, [location.search]); /// Depend on location.search to re-evaluate when URL search params change

  // Existing useEffect
  useEffect(() => {
    if (isModalOpen) {
      // Additional logic when modal is open
    }
  }, [isModalOpen]);

  useEffect(() => {
    const listItems = document.querySelectorAll('.thisStyle, .andThisStyle');

    listItems.forEach(item => {
      const line = item.querySelector('.line-breadcrumbs');
      if(line) {
        line.style.width = item.offsetWidth + 'px';
      }
    });
  }, []);
  // eslint-disable-next-line no-unused-vars
  const [diffInSeconds, setDiffInSeconds] = useState(null);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const now = new Date();
      const endDate = new Date(conference.endDate);

      const diffInMilliseconds = endDate.getTime() - now.getTime();
      const calculatedDiffInSeconds = Math.floor(diffInMilliseconds / 1000);

      setDiffInSeconds(calculatedDiffInSeconds * 1000);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [conference.endDate]);

  if (!isValidId) {
    return <NotFound />;
  }
  const scrollToSection = (id, offset) => {
    const element = document.getElementById(id);
    if (element) {
      const yOffset = offset || 0; // Смещение, если не указано, будет равно 0
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      setIsSticky(window.scrollY > 750); // Устанавливаем состояние isSticky в true, если прокрутка больше 500 пикселей
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // const handleOpenBankTransfer = () => {
  //   NotificationManager.error('Thank you for your request! This payment type does not work at this time. Use another one or write to us.', 'We are sorry', 5000);
  // }
  // eslint-disable-next-line no-unused-vars
  const [hasActiveFeature, setHasActiveFeature] = useState(false);

  useEffect(() => {
    console.log("Conference Name:", conference.eventName);
    console.log(Array.isArray(eventEL), typeof conference.eventName);
    console.log("Event Lists:", { eventInhalation, eventAutomotive, eventGEN, eventBTEL, eventEL, eventPA, eventLogistics, eventAdvancedPlastic, eventCleaning, eventFAMS });
    // Проверяем, активируется ли какая-либо из функций
    const isActive = eventInhalation.includes(conference.eventName) ||
        eventAutomotive.includes(conference.eventName) ||
        eventGEN.includes(conference.eventName) ||
        eventNurse.includes(conference.eventName) ||
        eventBTEL.includes(conference.eventName) ||
        eventEL.includes(conference.eventName) ||
        eventPA.includes(conference.eventName) ||
        eventPlantBased.includes(conference.eventName) ||
        eventLogistics.includes(conference.eventName) ||
        eventAdvancedPlastic.includes(conference.eventName) ||
        eventCleaning.includes(conference.eventName) ||
        eventFAMS.includes(conference.eventName);

    setHasActiveFeature(isActive);
  }, [conference.eventName]);

  const history = useHistory();





// Убедитесь, что conference и conference.eventName существуют











  const handleCreditCardChange = (e) => {
    const { name, value } = e.target;

    setCreditCard((prev) => {
      const updatedCreditCard = {
        ...prev,
        [name]: value,
        conferenceName: conference.eventName, // Добавляем имя конференции
        from: "stripe btel" // Добавляем значение "from"
      };
      validateCreditCardForm(updatedCreditCard); // Проверяем форму с обновленными данными
      return updatedCreditCard;
    });
  };

  const validateCreditCardForm = (data) => {
    const { firstName, lastName, email, job, company } = data;
    const isValid =
        firstName.trim() !== '' &&
        lastName.trim() !== '' &&
        email.trim() !== '' &&
        job.trim() !== '' &&
        company.trim() !== '';
    setIsCreditCardFormValid(isValid);
  };

  const eventFeatures = [
    {
      icon: <Megaphone className="tw-w-6 tw-h-6 tw-text-white" />,
      title: "Find the Latest Tech",
      description: "Meet all the latest tech providers in short five-minute pitches and on-site demos."
    },
    {
      icon: <Users className="tw-w-6 tw-h-6 tw-text-white" />,
      title: "Something for Everyone",
      description: "No matter what level of experience you have - there will be advanced and introductory level content."
    },
    {
      icon: <BookOpen className="tw-w-6 tw-h-6 tw-text-white" />,
      title: "The Regulator",
      description: "Hear from The Law Society and the SRA at the conference to stay up to date on latest policies."
    },
    {
      icon: <MessageCircle className="tw-w-6 tw-h-6 tw-text-white" />,
      title: "Thought-Provoking Talks",
      description: "Be inspired at Q&As with industry leaders and big-thinking talks from experts outside of our sector."
    },
    {
      icon: <Lightbulb className="tw-w-6 tw-h-6 tw-text-white" />,
      title: "New Ideas",
      description: "Learn about alternative ways of working and new operation strategies that can help your firm attract and retain the best talent."
    },
    {
      icon: <Heart className="tw-w-6 tw-h-6 tw-text-white" />,
      title: "The Best Community",
      description: "Our casual networking and fun side events give you the chance to connect and share practical, hands-on advice with your peers."
    }
  ];


  return (
      <div>
        <DiscountBanner handleOpenModal={handleOpenModal} />
        <Helmet>
          <title>
            {conference.eventName === "6TH ANNUAL BTEL SUMMIT & EXHIBITION"
                ? "6TH ANNUAL BTEL SUMMIT | BCF"
                : conference.eventName + " | BCF"}
          </title>
          <meta
              name="description"
              content={
                conference.eventName === "6TH ANNUAL BTEL SUMMIT & EXHIBITION"
                    ? "Welcome to the forefront of innovation in Biocompatibility, Toxicology, and Extractables & Leachables (E&L). We are thrilled to announce the upcoming 6th Annual BTEL Summit set to transpire from March 26th to 27th, 2025, in the city of Berlin, Germany.\n" +
                    "\n" +
                    "This summit promises an unparalleled opportunity for industry professionals, scientists, and experts to convene and explore the cutting-edge developments in these pivotal fields. Whether you specialize in medical devices, regulatory affairs, or analytical chemistry, this event is tailored to meet your professional interests and expand your horizons.\n" +
                    "\n" +
                    "Throughout the conference, esteemed speakers will delve into a spectrum of topics, ranging from the intricacies of biocompatibility testing and regulatory compliance to the nuances of E&L analysis and toxicological risk assessment.\n" +
                    "\n" +
                    "Each day of the conference is meticulously crafted to offer a distinctive focus:\n" +
                    "\n" +
                    "• Day one immerses attendees in the realm of Biocompatibility in Medical Devices, unraveling discussions on testing methodologies, regulatory frameworks, and the imperative ISO 10993-17 guidelines.\n" +
                    "\n" +
                    "• Day two illuminates the arena of Extractables & Leachables (E&L), offering invaluable insights into analytical strategies, risk mitigation programs, and breakthroughs reshaping the industry landscape.\n" +
                    "\n" +
                    "We invite you to seize this opportunity to connect with luminaries in your field, engage in thought-provoking discussions, and stay at the vanguard of scientific progress. Whether you opt for a single day or immerse yourself in the full three-day experience, this conference promises to be a catalyst for professional growth and collaboration.\n" +
                    "\n" +
                    "Join us in Berlin as we embark on a journey to redefine standards and drive innovation in biocompatibility, toxicology, and E&L"
                    : conference.description || "Описание конференции"
              }
          />
          <meta property="og:title" content={conference.eventName} />
          <meta
              property="og:description"
              content={
                conference.eventName === "6TH ANNUAL BTEL SUMMIT & EXHIBITION"
                    ? "Особое описание для BTEL SUMMIT"
                    : conference.description || "Описание конференции"
              }
          />
          <meta
              property="og:url"
              content={`https://bcf-events.com/single-conference/${id}`}
          />
          {/* Дополнительные теги для конкретных конференций */}
          {conference.eventName === "6TH ANNUAL BTEL SUMMIT & EXHIBITION" && (
              <meta name="keywords" content="BTEL, Conference, Annual Summit" />
          )}
        </Helmet>
        <Header />
        {/*<div>*/}
        {/*  {myEventBopap.includes(conference.eventName) && (*/}

        {/*      <Popup handleOpenModal={handleOpenModal} delegatePopupOpened={delegatePopupOpened} />*/}

        {/*  )}*/}
        {/*</div>*/}
        <main>
          {/* <PDFViewer width="100%" height={500}>
        <InvoicePDF invoiceData={invoiceData} />
      </PDFViewer> */}
          <section name="main">
            <div className="main-container">
              <div
                  className="background-single"
                  style={{
                    backgroundImage: `url(${conference.eventImage})`,
                  }}
              ></div>
              <div className="background-block">
                <div className="container" id="main">
                  <div className="breadcrumbs">
                    <ul>
                      <li className="andThisStyle">
                        <Link className="conference-link" to="/">
                          MAIN
                        </Link>
                      </li>
                      <li className="thisStyle">{conference.eventName}</li>
                    </ul>
                    <Breadcrumbs conferenceName={conference.eventName} />
                  </div>
                  <div className="title">
                    <h1>{conference.eventName}</h1>
                    <span className="line-title"></span>
                    <span className="mobile-title-line"></span>
                    <div>
                      {formateDate(conference)} {(conference.location !== 'online' && conference.location !== 'ONLINE') && (
                        <>
                          <h3>
                            {conference.hotels && conference.hotels.length > 0 ?
                                conference.hotels.map((hotel) => `Venue: ${hotel.hotelName}`).join(', ')
                                :
                                "To be announced soon"
                            }
                          </h3>
                          {conference.location}
                          {(eventCleaning.includes(conference.eventName) || eventOnlinePa.includes(conference.eventName)) && (
                              <h3>ONLINE on the Bigmarker Platform</h3>
                          )}
                        </>
                    )}

                      {/* Рендерим информацию об онлайн платформе, если событие входит в список eventAutomotive */}
                      {(eventAutomotive.includes(conference.eventName) || myEventVideo.includes(conference.eventName)
                          || eventOnlinePa.includes(conference.eventName)  || eventSweet.includes(conference.eventName) || eventOnline.includes(conference.eventName)
                          || eventEL.includes(conference.eventName) || eventIndustrialWater.includes(conference.eventName) || eventNurse.includes(conference.eventName)) && (
                          <h3>ONLINE on the Bigmarker Platform</h3>
                      )}
                    </div>
                  </div>
                  {/*{eventBTEL.includes(conference.eventName) && (*/}
                  {/*    <div className="second_title">*/}
                  {/*      <ul className="second_ditle_list">*/}
                  {/*        <h3>Summit Features:</h3>*/}
                  {/*        <li>• Workshop</li>*/}
                  {/*        <li>• Networking Activities</li>*/}
                  {/*        <li>• Event Application</li>*/}
                  {/*        <li>• Poster Session</li>*/}
                  {/*      </ul>*/}
                  {/*    </div>*/}
                  {/*)}*/}
                  {/*{eventInhalation.includes(conference.eventName) && (*/}
                  {/*    <div className="second_title">*/}
                  {/*      <ul className="second_ditle_list">*/}
                  {/*        <h3>Conference Features:</h3>*/}
                  {/*        <li>• Icebreaker Session</li>*/}
                  {/*        <li>• Networking Activities</li>*/}
                  {/*      </ul>*/}
                  {/*    </div>*/}
                  {/*)}*/}
                  {/*{eventPA.includes(conference.eventName) && (*/}
                  {/*    <div className="second_title">*/}
                  {/*      <ul className="second_ditle_list">*/}
                  {/*        <h3>Conference Features:</h3>*/}
                  {/*        <li>• Certified pre-conference workshop by David Svoboda, Canva Verified Expert and TEDx speaker</li>*/}
                  {/*        <li>• 2-day conference access</li>*/}
                  {/*        <li>• Lunches</li>*/}
                  {/*        <li>• Cocktail reception</li>*/}
                  {/*        <li>• Prosecco round table</li>*/}
                  {/*        <li>• Post Documentation</li>*/}
                  {/*        <li>• Recording</li>*/}
                  {/*      </ul>*/}
                  {/*    </div>*/}
                  {/*)}*/}
                  {/*{eventLogistics.includes(conference.eventName) && (*/}
                  {/*    <div className="second_title">*/}
                  {/*      <ul className="second_ditle_list">*/}
                  {/*        <h3>Summit Features:</h3>*/}
                  {/*        <li>• Icebreaker Session</li>*/}
                  {/*        <li>• Networking Activities</li>*/}
                  {/*        <li>• Event Application</li>*/}
                  {/*      </ul>*/}
                  {/*    </div>*/}
                  {/*)}*/}
                  {/*{eventAdvancedPlastic.includes(conference.eventName) && (*/}
                  {/*    <div className="second_title">*/}
                  {/*      <ul className="second_ditle_list">*/}
                  {/*        <h3>Conference Features:</h3>*/}
                  {/*        <li>• Icebreaker Session</li>*/}
                  {/*        <li>• Networking Activities</li>*/}
                  {/*      </ul>*/}
                  {/*    </div>*/}
                  {/*)}*/}
                  {/*{eventFAMS.includes(conference.eventName) && (*/}
                  {/*    <div className="second_title">*/}
                  {/*      <ul className="second_ditle_list">*/}
                  {/*        <h3>Conference Features:</h3>*/}
                  {/*        <li>• Icebreaker Session</li>*/}
                  {/*        <li>• Networking Activities</li>*/}
                  {/*        <li>• Event Application</li>*/}
                  {/*      </ul>*/}
                  {/*    </div>*/}
                  {/*)}*/}
                  {/*{eventPA25.includes(conference.eventName) && (*/}
                  {/*    <div className="second_title">*/}
                  {/*      <ul className="second_ditle_list">*/}
                  {/*        <h3>Conference Features:</h3>*/}
                  {/*        <li>• 2-day conference access</li>*/}
                  {/*        <li>• Lunches</li>*/}
                  {/*        <li>• Prosecco round table</li>*/}
                  {/*        <li>• Post Documentation</li>*/}
                  {/*        <li>• Recording</li>*/}
                  {/*        <li>• Dinner Board</li>*/}
                  {/*        <li>• Professional Photos</li>*/}
                  {/*      </ul>*/}
                  {/*    </div>*/}
                  {/*)}*/}
                  <div className="action-block block-icons-div">
                    <ul>
                      {!allEvents.includes(conference.eventName) && (
                          <li onClick={() => handleOpenModal("agenda")} className="download-agenda">
                      <span className="icon">
                        <img
                            src={require("../../assets/images/adgenda.png")}
                            alt=""
                        />
                      </span>
                            <p>
                              DOWNLOAD AGENDA
                            </p>
                          </li>  )}
                      {!allEvents.includes(conference.eventName) && (
                          <span className="line new-line-conference"></span>
                      )}
                      <li className="become-delegate" onClick={() => handleOpenModal("delegate")}>
                        <Link to={`?modal=delegate`} className="custom-link icon-link">
                        <span className="icon">
                          <img src={require("../../assets/images/delegate.png")} alt="" />
                        </span>
                        </Link>
                        <Link to={`?modal=delegate`} className="custom-link text-link">
                          <p>REGISTER NOW</p>
                        </Link>
                      </li>
                      {conference.linkedInUrl != null && (
                          <>
                            <span className="line new-line-conference"></span>
                            <li className="event-page">
                              <a className="popup-with-zoom-anim2" target="_blank" rel="noreferrer" href={conference.linkedInUrl}>
                        <span className="icon">
                          <img
                              src={require("../../assets/images/event.png")}
                              alt=""
                          />
                        </span>
                                <p>EVENT PAGE</p>
                              </a>
                            </li>
                          </>
                      )}
                      <span className="line new-line-conference"></span>
                      <li className="become-sponsor">
                        {conference.confExpensivness === 1 ? (
                            <Link className="popup-with-zoom-anim2" to="/sponsorship">
                          <span className="icon">
                            <img src={require("../../assets/images/sponsor.png")} alt="" />
                          </span>
                              <p>BECOME A SPONSOR</p>
                            </Link>
                        ) : (
                            <Link className="popup-with-zoom-anim2" to="/secondsponsorship">
                          <span className="icon">
                            <img src={require("../../assets/images/sponsor.png")} alt="" />
                          </span>
                              <p>BECOME A SPONSOR</p>
                            </Link>
                        )}
                      </li>
                    </ul>
                    <Modal
                        types="delegate"
                        currentType={currentType}
                        isOpen={isModalOpen}
                        onClose={handleCloseModal}
                    >
                      <div id="delegate-dialog" className="special-style">
                        <div className="popup-container"></div>
                        {/*{eventBTEL.includes(conference.eventName) && (*/}
                        {/*    <div*/}
                        {/*        className="conference-link conference-data-link"*/}
                        {/*        onClick={() => {*/}
                        {/*          setCurrentType('credit-card'); // Указываем тип модального окна*/}
                        {/*          setIsModalOpen(true); // Открываем модальное окно*/}
                        {/*        }}*/}
                        {/*    >*/}
                        {/*      <div className="popup-item item-1">*/}
                        {/*        <span className="text-popup">CREDIT CARD</span>*/}
                        {/*      </div>*/}
                        {/*    </div>*/}
                        {/*)}*/}

                            <Link
                                className="conference-link conference-data-link"
                                to={conference.slug ? `/stripe/${conference.slug}` : '#'}
                            >
                              <div className="popup-item item-1">
                                <span className="text-popup">CREDIT CARD</span>
                              </div>
                            </Link>

                        {eventBTEL.includes(conference.eventName) && (
                            <div
                                className="conference-link conference-data-link"
                                onClick={() => {
                                  setCurrentType('pay-pal'); // Указываем тип модального окна
                                  setIsModalOpen(true); // Открываем модальное окно
                                }}
                            >
                              <div className="popup-item item-2">
                                <span className="text-popup">PAYPAL</span>
                              </div>
                            </div>
                        )}
                        {!eventBTEL.includes(conference.eventName) && (
                            <Link className="conference-link conference-data-link" to={createModalLink('pay-pal')}>
                              <div className="popup-item item-2">
                                <span className="text-popup">PAYPAL</span>
                              </div>
                            </Link>
                        )}
                        <Link className="conference-link conference-data-link" to={createModalLink('bank-transfer')}>
                          <div className="popup-item item-3">
                            <span className="text-popup">BANK TRANSFER</span>
                          </div>
                        </Link>
                      </div>
                    </Modal>
                  </div>

                  <Modal
                      types="agenda"
                      currentType={currentType}
                      isOpen={isModalOpen}
                      onClose={handleCloseModal}
                  >
                    <div className="white-square">
                      <div className="scroll-container-wrapper">
                        <div className="scroll-container">
                          <form
                              className="form-bank"
                              onSubmit={(e) => handleFormSubmit(e)}>
                            <div className="form-group">
                              <label
                                  htmlFor="firstName"
                                  className="first-name-class"
                              >
                                Full Name*
                              </label>
                              <input
                                  type="text"
                                  id="first-name-input"
                                  name="fullName"
                                  required
                                  value={agenda.firstName}
                                  onChange={(e) => handleAgenda(e)}
                              />
                            </div>
                            <div className="form-group">
                              <label htmlFor="email">Email*</label>
                              <input
                                  type="email"
                                  id="email-input"
                                  name="email"
                                  required
                                  value={agenda.email}
                                  onChange={(e) => handleAgenda(e)}
                              />
                            </div>
                            <div className="form-group">
                              <label htmlFor="phone">Phone*</label>
                              <PhoneInput
                                  type="tel"
                                  id="phone"
                                  name="phone"
                                  required
                                  value={agenda.phone}
                                  onChange={handleAgendaPhoneChange}
                              />
                            </div>
                            <div className="form-group">
                              <p>How did you hear about us?*</p>
                              <div className="radio-buttons">
                                <label className="radio-option">
                                  <input
                                      type="radio"
                                      name="howHeard"
                                      required
                                      value="LinkedIn" // Каждой радиокнопке нужно явно задать значение
                                      checked={agenda.howHeard === "LinkedIn"}
                                      onChange={(e) => handleAgenda(e)}
                                      key="LinkedIn"
                                  />
                                  LinkedIn
                                </label>
                                <label className="radio-option">
                                  <input
                                      type="radio"
                                      name="howHeard"
                                      required
                                      value="Media Partner" // Каждой радиокнопке нужно явно задать значение
                                      checked={agenda.howHeard === "Media Partner"}
                                      onChange={(e) => handleAgenda(e)}
                                      key="Media Partner"
                                  />
                                  Media Partner
                                </label>
                                <label className="radio-option">
                                  <input
                                      type="radio"
                                      name="howHeard"
                                      required
                                      value="Email"
                                      checked={agenda.howHeard === "Email"}
                                      onChange={(e) => handleAgenda(e)}
                                  />
                                  Email
                                </label>
                                <label className="radio-option">
                                  <input
                                      type="radio"
                                      name="howHeard"
                                      required
                                      value="Colleagues"
                                      checked={agenda.howHeard === "Colleagues"}
                                      onChange={(e) => handleAgenda(e)}
                                  />
                                  Colleagues
                                </label>
                                <label className="radio-option">
                                  <input
                                      type="radio"
                                      name="howHeard"
                                      required
                                      value="Other"
                                      checked={agenda.howHeard === "Other"}
                                      onChange={(e) => handleAgenda(e)}
                                  />
                                  Other
                                </label>
                              </div>
                            </div>

                            <button type="submit" className="canister-button">
                              {isLoading ? (
                                  <ThreeDots height="40" width="40" color="white" />
                              ) : (
                                  "Request"
                              )}
                            </button>
                          </form>
                        </div>
                      </div>
                    </div>
                  </Modal>
                  {eventBTEL.includes(conference.eventName) && (
                      <Modal
                          types="credit-card"
                          currentType={currentType}
                          isOpen={isModalOpen}
                          onClose={handleCloseModal}
                      >
                        <div className="bank-style special-style">
                          <div className="white-square">
                            <div className="scroll-container-wrapper">
                              <div className="scroll-container add-mobile-wrapper">
                                <form className="form-bank" onSubmit={handleCreditCardSubmit}>
                                  <div className="form-group">
                                    <label htmlFor="first-name" className="first-name-class">First Name*</label>
                                    <input
                                        type="text"
                                        id="first-name"
                                        name="firstName"
                                        required
                                        value={creditCard.firstName}
                                        onChange={handleCreditCardChange}
                                    />
                                  </div>
                                  <div className="form-group">
                                    <label htmlFor="last-name">Last Name*</label>
                                    <input
                                        type="text"
                                        id="last-name"
                                        name="lastName"
                                        required
                                        value={creditCard.lastName}
                                        onChange={handleCreditCardChange}
                                    />
                                  </div>
                                  <div className="form-group">
                                    <label htmlFor="email">Email*</label>
                                    <input
                                        type="email"
                                        id="email"
                                        name="email"
                                        required
                                        value={creditCard.email}
                                        onChange={handleCreditCardChange}
                                    />
                                  </div>
                                  <div className="form-group">
                                    <label htmlFor="phone">Phone*</label>
                                    <input
                                        type="tel"
                                        id="phone"
                                        name="phone"
                                        required
                                        value={creditCard.phone}
                                        onChange={handleCreditCardChange}
                                    />
                                  </div>
                                  <div className="form-group">
                                    <label htmlFor="job-title">Job Title*</label>
                                    <input
                                        type="text"
                                        id="job-title"
                                        name="job"
                                        required
                                        value={creditCard.job}
                                        onChange={handleCreditCardChange}
                                    />
                                  </div>
                                  <div className="form-group">
                                    <label htmlFor="company">Company*</label>
                                    <input
                                        type="text"
                                        id="company"
                                        name="company"
                                        required
                                        value={creditCard.company}
                                        onChange={handleCreditCardChange}
                                    />
                                  </div>
                                  <div className="form-group">
                                    <label htmlFor="promo">Promo Code</label>
                                    <input
                                        type="text"
                                        id="promo"
                                        name="promo"
                                        value={creditCard.promo}
                                        onChange={handleCreditCardChange}
                                    />
                                  </div>
                                  <button
                                      type="submit"
                                      className={`register-button ${isCreditCardFormValid ? 'active' : 'inactive'}`}
                                      disabled={!isCreditCardFormValid}
                                  >
                                    Register
                                  </button>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Modal>
                  )}

                  {eventBTEL.includes(conference.eventName) && (
                      <>
                        {isPayPalDataFormVisible ? (
                            <Modal
                                types="pay-pal"
                                currentType={currentType}
                                isOpen={isModalOpen}
                                onClose={handleCloseModal}
                            >
                              <div className="bank-style special-style">
                                <div className="white-square">
                                  <div className="scroll-container-wrapper">
                                    <div className="scroll-container add-mobile-wrapper">
                                      <form
                                          className="form-bank"
                                          onSubmit={handlePayPalSubmit}  // Используем нашу функцию для обработки формы
                                      >
                                        <div className="form-group">
                                          <label htmlFor="first-name" className="first-name-class">First Name*</label>
                                          <input
                                              type="text"
                                              id="first-name"
                                              name="firstName"
                                              required
                                              value={payPal.firstName}
                                              onChange={handlePayPal}
                                          />
                                        </div>
                                        <div className="form-group">
                                          <label htmlFor="last-name">Last Name*</label>
                                          <input
                                              type="text"
                                              id="last-name"
                                              name="lastName"
                                              required
                                              value={payPal.lastName}
                                              onChange={handlePayPal}
                                          />
                                        </div>
                                        <div className="form-group">
                                          <label htmlFor="email">Email*</label>
                                          <input
                                              type="email"
                                              id="email"
                                              name="email"
                                              required
                                              value={payPal.email}
                                              onChange={handlePayPal}
                                          />
                                        </div>
                                        <div className="form-group">
                                          <label htmlFor="phone">Phone*</label>
                                          <input
                                              type="tel"
                                              id="phone"
                                              name="phone"
                                              required
                                              value={payPal.phone}
                                              onChange={handlePayPal}
                                          />
                                        </div>
                                        <div className="form-group">
                                          <label htmlFor="job-title">Job Title*</label>
                                          <input
                                              type="text"
                                              id="job-title"
                                              name="job"
                                              required
                                              value={payPal.job}
                                              onChange={handlePayPal}
                                          />
                                        </div>
                                        <div className="form-group">
                                          <label htmlFor="company">Company*</label>
                                          <input
                                              type="text"
                                              id="company"
                                              name="company"
                                              required
                                              value={payPal.company}
                                              onChange={handlePayPal}
                                          />
                                        </div>
                                        <div className="form-group">
                                          <label htmlFor="promo">Promo Code</label>
                                          <input
                                              type="text"
                                              id="promo"
                                              name="promo"
                                              value={payPal.promo}
                                              onChange={handlePayPal}
                                          />
                                        </div>
                                        <button
                                            type="submit"
                                            className={`register-button ${isFormValid ? 'active' : 'inactive'}`}
                                            disabled={!isFormValid}
                                        >
                                          Register
                                        </button>
                                      </form>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Modal>


                        ) : (
                            <Modal
                                types="pay-pal"
                                currentType={currentType}
                                isOpen={isModalOpen}
                                onClose={handleCloseModal}
                            >
                              <div>
                                {conference.payPalPayments && conference.payPalPayments.length > 0 && (
                                    <div className="paypal-square">
                                      <div className="scroll-container-wrapper">
                                        <div className="scroll-container">
                                          {sortedPaymentsForPayPal.map((pay) => (
                                              <div key={pay.id} className="paypal-wrapper">
                                                <div>
                                                  <h4>{pay.paymentName}</h4>
                                                  <p className="price-paypal">1 x {pay.price} EUR</p>
                                                </div>
                                                <div className="paypal-width">
                                                  <PayPalScriptProvider
                                                      options={{
                                                        clientId:
                                                            'AYoVCFKvcXHDj0pcFTGhsqYjaH4dJnWibwLvIda5WAlELIXpQcqUM_y37SpKrdhB_3ts2RiH6kpYwf1i',
                                                        currency: 'EUR',
                                                      }}
                                                  >
                                                    <PayPalButtons
                                                        createOrder={(data, actions) =>
                                                            createOrder(data, actions, pay.price)
                                                        }
                                                        onApprove={onApprove}
                                                        style={{ layout: 'horizontal' }}
                                                    />
                                                  </PayPalScriptProvider>
                                                </div>
                                              </div>
                                          ))}
                                        </div>
                                      </div>
                                    </div>
                                )}
                              </div>
                            </Modal>
                        )}
                      </>
                  )}
                  <Modal
                      types="bank-transfer"
                      currentType={currentType}
                      isOpen={isModalOpen}
                      onClose={handleCloseModal}
                  >
                    <div className="bank-style  special-style">
                      <div className="white-square">
                        <div className="scroll-container-wrapper">
                          <div className="scroll-container add-mobile-wrapper">

                            <form
                                className="form-bank"
                                onSubmit={(e) =>
                                    postMessageToSlack(e, "bank-transfer")
                                }
                            >
                              <div className="form-group">
                                <label
                                    htmlFor="first-name"
                                    className="first-name-class"
                                >
                                  First Name*
                                </label>
                                <input
                                    type="text"
                                    id="first-name"
                                    name="firstName"
                                    required
                                    value={bank.firstName}
                                    onChange={(e) => handleBank(e)}
                                />
                              </div>
                              <div className="form-group">
                                <label htmlFor="last-name">Last Name*</label>
                                <input
                                    type="text"
                                    id="last-name"
                                    name="lastName"
                                    required
                                    value={bank.lastName}
                                    onChange={(e) => handleBank(e)}
                                />
                              </div>
                              <div className="form-group">
                                <label htmlFor="email">Email*</label>
                                <input
                                    type="email"
                                    id="email"
                                    name="email"
                                    required
                                    value={bank.email}
                                    onChange={(e) => handleBank(e)}
                                />
                              </div>
                              <div className="form-group">
                                <label htmlFor="job-title">Job Title*</label>
                                <input
                                    type="text"
                                    id="job-title"
                                    name="job"
                                    required
                                    value={bank.job}
                                    onChange={(e) => handleBank(e)}
                                />
                              </div>
                              <div className="form-group">
                                <label htmlFor="company-name">
                                  Company Name*
                                </label>
                                <input
                                    type="text"
                                    id="company-name"
                                    name="company"
                                    required
                                    value={bank.company}
                                    onChange={(e) => handleBank(e)}
                                />
                              </div>
                              <div className="form-group">
                                <label htmlFor="company-address">
                                  Company Address*
                                </label>
                                <input
                                    type="text"
                                    id="company-address"
                                    name="address"
                                    required
                                    value={bank.address}
                                    onChange={(e) => handleBank(e)}
                                />
                              </div>
                              <div className="form-group">
                                <label htmlFor="city">City*</label>
                                <input
                                    type="text"
                                    id="city"
                                    name="city"
                                    required
                                    value={bank.city}
                                    onChange={(e) => handleBank(e)}
                                />
                              </div>
                              <div className="form-group">
                                <label htmlFor="vat">
                                  VAT of the company (only for EU company)
                                </label>
                                <input
                                    type="text"
                                    id="vat"
                                    name="vat"
                                    value={bank.vat}
                                    onChange={(e) => handleBank(e)}
                                />
                              </div>
                              <div className="form-group">
                                <label htmlFor="phone">Phone*</label>
                                <PhoneInput
                                    type="tel"
                                    id="phone"
                                    name="phone"
                                    required
                                    value={bank.phone}
                                    onChange={handlePhoneChange}
                                />
                              </div>
                              <div className="form-group">
                                <label htmlFor="promo">Promo</label>
                                <input
                                    type="text"
                                    id="promo"
                                    name="promo"
                                    value={bank.promo}
                                    onChange={(e) => handleBank(e)}
                                />
                              </div>
                              <div className="form-group">
                                <label htmlFor="ticket-type">Ticket Type*</label>
                                <select
                                    id="ticket-type"
                                    name="ticket"
                                    required
                                    value={bank.ticket.name !== "" ? `${bank.ticket.name}:${bank.ticket.price}` : ""}
                                    onChange={(e) => handleBank(e)}
                                >
                                  <option value="">Select one</option>
                                  {sortedPaymentsTwo.map((payment, index) => (
                                      <option key={`option-${index}`} value={`${payment.paymentName}:${payment.price}`}>
                                        {`${payment.paymentName}: ${"€" + payment.price}`}
                                      </option>
                                  ))}
                                </select>
                              </div>
                              <div className="form-group1">
                                <label class="galochka">
                                  <input
                                      type="checkbox"
                                      className="custom-checkbox"
                                      onChange={handleCheckboxChange}
                                      checked={isChecked}
                                  />
                                  I agree with&nbsp;<a href="https://bcf-events.com/terms">Terms and Conditions</a>
                                </label>
                              </div>
                              <button
                                  type="submit"
                                  className={`register-button ${isChecked ? 'active' : 'inactive'}`}
                                  disabled={!isChecked}
                              >
                                {isLoading ? <ThreeDots height="40" width="40" color="white"/> : "Register"}
                              </button>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Modal>
                  {!eventBTEL.includes(conference.eventName) && (
                      <Modal
                          types="pay-pal"
                          currentType={currentType}
                          isOpen={isModalOpen}
                          onClose={handleCloseModal}
                      >
                        <div
                            //id="paypal-dialog"
                            //className="zoom-anim-dialog mfp-hide my-custom-popup-styles"
                        >
                          {conference.payPalPayments && conference.payPalPayments.length > 0 && (
                              <div className="paypal-square">
                                <div className="scroll-container-wrapper">
                                  <div className="scroll-container">
                                    {sortedPaymentsForPayPal.map((pay) => (
                                        <div key={pay.id} className="paypal-wrapper">
                                          <div>
                                            <h4>{pay.paymentName}</h4>
                                            <p className="price-paypal">1 x {pay.price} EUR</p>
                                          </div>
                                          <div className="paypal-width">
                                            <PayPalScriptProvider options={{ clientId: "AYoVCFKvcXHDj0pcFTGhsqYjaH4dJnWibwLvIda5WAlELIXpQcqUM_y37SpKrdhB_3ts2RiH6kpYwf1i", currency: "EUR" }}>
                                              <PayPalButtons
                                                  createOrder={(data, actions) => createOrder(data, actions, pay.price)}
                                                  onApprove={onApprove}
                                                  style={{ layout: "horizontal" }}
                                              />
                                            </PayPalScriptProvider>
                                          </div>
                                        </div>
                                    ))}
                                  </div>
                                </div>
                              </div>
                          )}
                        </div>
                      </Modal>
                  )}
                </div>
              </div>

              <nav className={`page-navigation-container ${isSticky ? 'sticky' : ''}`}>
                <ul className="page-navigation">
                  <li><h2>Page Navigation</h2></li>
                  <li><a onClick={() => scrollToSection('price')}>Register now</a></li>
                  {conference.speakers && conference.speakers.length > 0 && (
                      <li><a onClick={() => scrollToSection('speakers', -175)}>Speakers</a></li>  )}
                  <li><a onClick={() => scrollToSection('about', -175)}>Conference overview</a></li>
                  <li><a onClick={() => scrollToSection('key', -175)}>Key points</a></li>
                  {conference.sponsors && conference.sponsors.length > 0 && (
                      <li><a onClick={() => scrollToSection('sponsors', -175)}>Sponsors</a></li> )}
                  <li><a onClick={() => scrollToSection('gallery', -175)}>Gallery</a></li>
                  {conference.hotels && conference.hotels.length > 0 && (
                      <li><a onClick={() => scrollToSection('venue', -175)}>Venue</a></li>  )}
                  <li><a onClick={() => scrollToSection('contact', -175)}>Contact us</a></li>
                </ul>
              </nav>
            </div>
            {conference.hotelMap && conference.hotelMap.length > 0 &&(
                <div className="centered-block"
                     style={{ paddingBottom: 0, paddingTop: '25px' }}>
                  <a className="popup-with-zoom-anim2" target="_blank" rel="noreferrer" href={conference.hotelMap}>
                    <button className="neon-button">
                      VIEW PRE-CONFERENCE TEASER
                    </button>
                  </a>
                </div>
            )}
          </section>

          {conference.speakers && conference.speakers.length > 0 && (
              <section className="marquee-section">
              <div className="marquee-container">
                  <ul className="marquee">
                    {conference.speakers.map((speaker, index) => (
                        speaker.logo && speaker.logo.length > 0 && (
                            <li key={speaker.id || index} className="marquee-item">
                              <div className="marquee-item-wrapper">
                                <img src={speaker.logo} alt={`Logo ${index}`} />
                              </div>
                            </li>
                        )
                    ))}
                  </ul>
                </div>
              </section>
          )}


          {conference.speakers && conference.speakers.length > 0 && (
              <section className="section-speakers" id="speakers">

              <div className="container">
                <h2 className="title-about title-about-single">
                {eventBTEL.includes(conference.eventName)
                        ? "Advisory Board and Speakers"
                        : "Speakers"
                    }
                  </h2>
                  <div className="line-container">
                    <span className="line-about"></span>

                  </div>
                  {myEventDescription.includes(conference.eventName) && (
                      <p className="description-special add-about">
                        The Advisory Board is composed of experienced and distinguished thought leaders from various industries.
                        This group plays a crucial role in advising the BCF on the strategic direction of the conference program,
                        offering recommendations for speakers, shaping content themes, and providing valuable insights into the research community.
                      </p>
                  )}

                  <ul className="grid-container">
                    {conference.speakers &&
                        conference.speakers.map((speaker) => (
                            <li className="grid-item" key={speaker.id}>
                              <div>
                                <a className="popup-with-zoom-anim speaker-style" href={`#small-dialog-${speaker.id}`}>
                                  <img src={`${speaker.profileImage}`} alt="" className="flip-img mobile-image" />
                                </a>
                              </div>
                              <p className="name">
                              {speaker.fullName}
                              </p>
                              <p className="position">{speaker.position}</p>
                              {myEventVideo.includes(conference.eventName) && (
                                  <p className="company">
                                    <a href={speaker.speakersUrlLogo} style={{ color: '#00005A', fontWeight: 'bold', textDecoration: 'none' }}>
                                      {speaker.company}
                                    </a>
                                  </p>
                              )}
                              <a href={speaker.speakersUrlLogo} target="_blank" rel="noreferrer">
                                <img className="logo-company" src={speaker.logo} alt="" />
                              </a>
                              <div className="scroll-container-wrapper">
                                <div id={`small-dialog-${speaker.id}`} className="zoom-anim-dialog mfp-hide my-custom-popup-style scroll-container">
                                  <div className="popup-flex">
                                    <img className="popup-img" src={speaker.profileImage} alt="" />
                                    <div className="popup-direction">
                                      <p className="name mobile-name">
                                      {speaker.fullName}
                                      </p>
                                      <p className="position mobile-position">{speaker.position}</p>
                                      <p className="company mobile-company">
                                        <a href={speaker.speakersUrlLogo} style={{ color: '#00005A', fontWeight: 'bold', textDecoration: 'underline' }}>
                                          {speaker.company}
                                        </a>
                                      </p>
                                    </div>
                                  </div>
                                  <p className="popup-desc" dangerouslySetInnerHTML={{ __html: speaker?.description }}></p>
                                </div>
                              </div>
                            </li>
                        ))}
                  </ul>
                </div>
              </section>
          )}


          {eventPA.includes(conference.eventName) && (
              <div className="container">
                <section className="about-container" id="about">
                  <div className="line-container" style={{display: 'flex', flexDirection: 'column', marginTop: '5px', marginBottom: '-10px' }}>
                    <h3 className="title-about title-about-single" style={{ marginTop: '0', marginBottom: '-0px' }}>
                      Pre-Conference Workshop
                    </h3>
                    <span className="line-about"></span>
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center'}} className="content-wrapper">
                    <div className="text-wrapper" >
                      <div>
                        <h2 style={{ textAlign: 'left', paddingLeft: '9%'}}>Mastering Presentation Design:</h2>
                        <p style={{ textAlign: 'left', paddingLeft: '9%', paddingTop: '20px' }}>• Enhancing Canva Skills</p>
                        <p style={{ textAlign: 'left', paddingLeft: '9%' }}>• Creating Effective Presentations</p>
                        <p style={{ textAlign: 'left', paddingLeft: '9%' }}>• Working with Templates</p>
                        <p style={{ textAlign: 'left', paddingLeft: '9%' }}>• Using AI to Overcome Creative Block</p>
                        <p style={{ textAlign: 'left', paddingLeft: '9%' }}>• Official Certificates After the Workshop</p>
                      </div>
                      <p style={{ textAlign: 'left', paddingRight: '9%', paddingLeft: '9%'}}>
                        To make the most of this experience, we recommend that you bring your own laptop to try out new skills and features.
                      </p>
                    </div>
                    <div style={{ flexShrink: 0, paddingTop: '120px', width: '35%', height: '100%' }} className="image-wrapper">
                      <ul className="grid-container-teaser">
                        {conference.speakers &&
                            conference.speakers
                                .filter((speaker) => {
                                  // Фильтрация по идентификаторам или именам спикеров
                                  return (
                                      speakerNamesToInclude.includes(speaker.fullName)
                                  );
                                })
                                .map((speaker) => (
                                    <li className="grid-item" key={speaker.id}>
                                      <div>
                                        <a className="popup-with-zoom-anim speaker-style"
                                           href={`#small-dialog-${speaker.id}`}
                                        >
                                          <img
                                              src={`${speaker.profileImage}`}
                                              alt=""
                                              className="flip-img mobile-image"
                                          />
                                        </a>
                                      </div>
                                      <p className="name">{speaker.fullName}</p>
                                      <p className="position">{speaker.position}</p>
                                      <p className="company">
                                        <a href={speaker.speakersUrlLogo}  style={{ color: '#00005A', fontWeight: 'bold', textDecoration: 'none' }}>
                                          {speaker.company}
                                        </a>
                                      </p>
                                      <img
                                          className="logo-company"
                                          src={speaker.logo}
                                          alt=""
                                          style={{ width: '30%', height: '30%', objectFit: 'cover' }}
                                      />
                                      <div className="scroll-container-wrapper">
                                        <div id={`small-dialog-${speaker.id}`} className="zoom-anim-dialog mfp-hide my-custom-popup-style scroll-container">
                                          <div className="popup-flex">
                                            <img className="popup-img" src={speaker.profileImage} alt="" />
                                            <div className="popup-direction">
                                              <p className="name mobile-name">{speaker.fullName}</p>
                                              <p className="position mobile-position">
                                                {speaker.position}
                                              </p>
                                              <p className="company mobile-company">
                                                <a href={speaker.speakersUrlLogo} style={{ color: '#00005A', fontWeight: 'bold', textDecoration: 'underline' }}>
                                                  {speaker.company}
                                                </a>
                                              </p>
                                            </div>
                                          </div>
                                          <p className="popup-desc" dangerouslySetInnerHTML={{ __html: speaker?.description }}></p>
                                        </div>
                                      </div>
                                    </li>
                                ))}
                      </ul>
                      {/*<img*/}
                      {/*    src={require("../../assets/images/david.png")}*/}
                      {/*    alt=""*/}
                      {/*    style={{ width: '100%', height: '100%', objectFit: 'cover' }}*/}
                      {/*/>*/}
                    </div>
                  </div>
                  <button
                      className="action-button-register"
                      onClick={() => handleOpenModal("agenda")}
                      style={{ padding: '10px 33px' }}
                  >
                    Learn now
                  </button>
                </section>
              </div>
          )}

          <div className="container">
            <section className="about-container" id="about">
              <h2 className="title-about title-about-single">
              About the {" "}
                {conference.conferenceType === 0 ? "conference" : "summit"}
              </h2>
              <div className="line-container">
                <span className="line-about"></span>
              </div>

              <p className="description-about add-about" dangerouslySetInnerHTML={{ __html: conference.aboutEvent }}></p>
              {/*<div className="eventPhotos">*/}
              {/*  <p className={`description-about ${conference.speakers && conference.speakers.length > 0 ? 'add-about-active' : 'add-about'}`}*/}
              {/*     dangerouslySetInnerHTML={{ __html: conference.aboutEvent }}></p>*/}
              {/*  {conference.speakers && conference.speakers.length > 0 && (*/}
              {/*      <PersonalPhotos speakers={conference.speakers} />*/}
              {/*  )}*/}
              {/*</div>*/}
              {!myEventListButtons.includes(conference.eventName) && (
                  <div style={{ display: 'flex', gap: '20px', flexWrap: 'wrap', justifyContent: 'center' }}>
                    <button
                        className="action-button-register"
                        onClick={() => handleOpenModal("delegate")}
                    >
                      Register now
                    </button>
                    <button
                        className="action-button-agenda"
                        onClick={() => handleOpenModal("agenda")}
                    >
                      Download PDF agenda
                    </button>
                  </div>
              )}
              {conference.hotelMap && conference.hotelMap.length > 0 &&(
                  <a className="popup-with-zoom-anim2" target="_blank" href={conference.hotelMap} rel="noreferrer">
                    <button className="neon-button">
                      Free Pre-Summit Teaser
                    </button>
                  </a>

              )}
              {/*<button className="register-button"><ThreeDots  height="40" width="40" color="white"  /></button>*/}
            </section>
          </div>
          {myEventVideo.includes(conference.eventName) && (
              <section className="videobox">
                <div className="playerbox">
                  <div className="player">
                    <iframe width="1000" height="450" src="https://www.youtube.com/embed/id7iybrQrKg?si=egglYoPzj9nel3Ew" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                  </div>
                </div>
              </section>)}
          {eventAdvancedPlastic.includes(conference.eventName) && (
              <section className="videobox">
                <div className="playerbox">
                  <div className="player">
                    <iframe
                        src="https://www.youtube.com/embed/edqyedO4gRo?si=kyHx5K2tHoshzy1s"
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen
                        className="responsive-iframe"
                    ></iframe>
                  </div>
                </div>
              </section>
          )}


          <section
              className="section-keys"
              id="key"
          >
            <div className="key-container container">
              <div className="key-container-block">
                <h3 className="key-title">KEY POINTS</h3>
                <ul>
                  {conference.keyPoints?.map((point, index) => (
                      <div
                          key={point.id}
                          className={`key-block ${index > 2 && !showAll ? 'hidden' : ""}`}>
                        <img
                            src={`${keyImages[index % keyImages.length]}`}
                            alt=""
                        />
                        <li>{point.keyName}</li>
                      </div>
                  ))}
                  {conference.keyPoints?.length > 2 && (
                      <li className={`key-point-btn ${!showAll ? 'btn-hidden' : ""}`}>
                        <span onClick={() => setShowAll(!showAll)}>
                          {showAll ? <FontAwesomeIcon icon={faAngleDoubleUp} /> : <FontAwesomeIcon icon={faAngleDoubleDown} />}
                        </span>
                      </li>
                  )}
                </ul>
              </div>
              <span
                  className="vertical-line"
              ></span>
              <div className="mobile-line-container">
                <span className="mobile-title-line"></span>
              </div>
              <div className="key-container-block">
                <h3 className="key-title">WHO SHOULD ATTEND</h3>
                <ul>
                  {conference.attendReasons?.map((reason, index) => (
                      <div
                          key={reason.id}
                          className={`key-block ${index > 2 && !showAll ? 'hidden' : ""}`}>
                        <img
                            src={`${keyImages[index % keyImages.length]}`}
                            alt=""
                        />
                        <li>{reason.reason}</li>
                      </div>
                  ))}
                  {conference.attendReasons?.length > 2 && (
                      <li className={`key-point-btn ${!showAll ? 'btn-hidden' : ""}`}>
                        <span onClick={() => setShowAll(!showAll)}>
                          {showAll ? <FontAwesomeIcon icon={faAngleDoubleUp} /> : <FontAwesomeIcon icon={faAngleDoubleDown} />}
                        </span>
                      </li>
                  )}
                </ul>
              </div>
            </div>
            <div style={{ display: 'flex', marginTop: '25px', justifyContent: 'center' }}>
              <button
                  className="action-button-register"
                  onClick={() => handleOpenModal("delegate")}
              >
                Register Now
              </button>
            </div>
          </section>
          <section className="tw-my-20">
            <EventFeatures features={eventFeatures} />
          </section>
              <section className="section-register">
                <div id="price" className="container">
                  <h2
                      className="title-about title-about-single"
                  >How to register?</h2>
                  <div className="line-container">
                    <span className="line-about"></span>
                  </div>
                  <div className="payment-buttons horizontal-buttons">
                    <div className="payment-buttons horizontal-buttons-new">

                      {/* Оплата кредитной картой */}
                      <div className="payment-option-new">
                        <Link
                            className="payment-link-new conference-data-link"
                            to={`/stripe/${conference.slug}`}
                        >
                          <div className="popup-item-new item-1">
                            <span className="text-popup">CREDIT CARD</span>
                          </div>
                        </Link>
                        <Link to={conference.slug ? `/stripe/${conference.slug}` : '#'}>
                          <button className="order-button-new">Book a Ticket</button>
                        </Link>
                      </div>

                      {/* Оплата через PayPal */}
                      <div className="payment-option-new">
                        <Link
                            className="payment-link-new conference-data-link"
                            to={createModalLink('pay-pal')}
                        >
                          <div className="popup-item-new item-2">
                            <span className="text-popup">PAYPAL</span>
                          </div>
                        </Link>
                        <Link to={createModalLink('pay-pal')}>
                          <button className="order-button-new">Book a Ticket</button>
                        </Link>
                      </div>

                      {/* Банковский перевод */}
                      <div className="payment-option-new">
                        <Link
                            className="payment-link-new conference-data-link"
                            to={createModalLink('bank-transfer')}
                        >
                          <div className="popup-item-new item-3">
                            <span className="text-popup">BANK TRANSFER</span>
                          </div>
                        </Link>
                        <Link to={createModalLink('bank-transfer')}>
                          <button className="order-button-new">Book a Ticket</button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

          {galleryCreator.includes(conference.eventName) && (
              <section
                  className="section-gallery"
                  id="gallery"
              >
                <div className="container">
                  <h2 className="title-gallery title-about-single">Gallery</h2>
                  <div className="line-container">
                    <span className="line-about"></span>
                  </div>
                  <span className="opacity-gallery">124</span>
                </div>
                <Swiper
                    id="mainSwiper"
                    ref={(swiper) => (swiperRef.current = swiper)}
                    spaceBetween={10}
                    pagination={{ clickable: true }}
                    centeredSlides={true}
                    initialSlide={3}
                    navigation={{
                      nextEl: '.right-arrow',
                      prevEl: '.left-arrow',
                    }}
                    breakpoints={{
                      319: {
                        slidesPerView: 3
                      },
                      767: {
                        slidesPerView: 5
                      }
                    }}
                >
                  {galleryImages.map((image, index) => (
                      <SwiperSlide key={`${image}-${index}`} onClick={() => handleImageClick(index)}>
                        <img src={image} alt={`Gallery image ${index}`} className="slide-image" />
                      </SwiperSlide>
                  ))}
                </Swiper>
                <div className="arrow-container home-arrow-container single-arrow-container">
                  {/* Раскомментируйте стрелки навигации, если они нужны */}
                  {/* <div className="left-arrow" id="btnPrev2"></div>
      <div className="right-arrow" id="btnNext2"></div> */}
                </div>
              </section>
          )}
          {conference.sponsors && conference.sponsors.length > 0 && (
              <section className="section-speakers" id="sponsors">
                <div className="container">
                  <h2
                      className="title-about title-about-single"
                  >
                    Sponsors
                  </h2>

                  <div className="line-container">
                    <span className="line-about"></span>
                  </div>
                  <ul className="grid-container-sponsor">
                    {conference.sponsors &&
                        conference.sponsors
                            .sort((a, b) => a.sponsorType - b.sponsorType) // Сортировка по типу спонсора
                            .map((sponsor) => (
                                <a href={sponsor.sponsorUrlLogo} key={sponsor.id} target="_blank" rel="noopener noreferrer">
                                  <li className="grid-item" key={sponsor.id} style={{ marginBottom: '-160px' }}>
                                    <div className="new-sponsors">
                                      <p
                                          className="name"
                                      >
                                        {getSponsorType(sponsor.sponsorType)}
                                      </p>

                                      <img
                                          className="new-logo-sponsor"
                                          src={sponsor.sponsorLogo}
                                          alt=""
                                      />
                                    </div>
                                  </li>
                                </a>))}
                  </ul>
                </div>
                <div style={{ display: 'flex', marginTop: '25px', justifyContent: 'center' }}>
                  <Link className="popup-with-zoom-anim2" to="/secondsponsorship">
                    <button
                        className="action-button-register"
                        onClick={() => handleOpenModal("delegate")}
                    >
                      Become a sponsor
                    </button>
                  </Link>
                </div>
              </section>
          )}
          {/*{conference.sponsors && conference.sponsors.length > 0 && (*/}
          {/*    <section className="section-sponsors">*/}
          {/*      <div className="container">*/}
          {/*        <div className="sponsors-wrapper">*/}
          {/*          {conference.sponsors.some(sponsor => sponsor.sponsorType === 0) && (*/}
          {/*              <div className="price-sponsors-container custom-sponsors">*/}
          {/*                <h3 className="price-sponsors">*/}
          {/*                  {conference.sponsors.some(sponsor => sponsor.sponsorType === 0) ? (*/}
          {/*                      conference.sponsors.filter(sponsor => sponsor.sponsorType === 0).length > 1 ? (*/}
          {/*                          'Diamond Sponsors'*/}
          {/*                      ) : (*/}
          {/*                          'Diamond Sponsor'*/}
          {/*                      )*/}
          {/*                  ) : null}*/}
          {/*                </h3>*/}
          {/*                <div className="line-container">*/}
          {/*                  <span className="line-about"></span>*/}
          {/*                </div>*/}
          {/*                <div className="sponsors-image">*/}
          {/*                {conference.sponsors*/}
          {/*                    .filter(sponsor => sponsor.sponsorType === 0)*/}
          {/*                    .map((sponsor, index) => (*/}
          {/*                      <a href={sponsor.sponsorUrlLogo} key={sponsor.id} target="_blank" rel="noopener noreferrer">*/}
          {/*                        <img*/}
          {/*                            src={sponsor.sponsorLogo}*/}
          {/*                            className="sponsor-image"*/}
          {/*                            key={sponsor.id}*/}
          {/*                        />*/}
          {/*                        </a>))}*/}
          {/*                </div>*/}
          {/*              </div>*/}
          {/*          )}*/}
          {/*          {conference.sponsors.some(sponsor => sponsor.sponsorType === 1) && (*/}
          {/*              <div className="price-sponsors-container custom-sponsors">*/}
          {/*                <h3 className="price-sponsors">*/}
          {/*                  {conference.sponsors.some(sponsor => sponsor.sponsorType === 1) ? (*/}
          {/*                      conference.sponsors.filter(sponsor => sponsor.sponsorType === 1).length > 1 ? (*/}
          {/*                          'Platinum Sponsors'*/}
          {/*                      ) : (*/}
          {/*                          'Platinum Sponsor'*/}
          {/*                      )*/}
          {/*                  ) : null}*/}
          {/*                </h3>*/}
          {/*                <div className="line-container">*/}
          {/*                  <span className="line-about"></span>*/}
          {/*                </div>*/}
          {/*                <div className="sponsors-image">*/}
          {/*                {conference.sponsors*/}
          {/*                    .filter(sponsor => sponsor.sponsorType === 1)*/}
          {/*                    .map((sponsor, index) => (*/}

          {/*                      <a href={sponsor.sponsorUrlLogo} key={sponsor.id} target="_blank" rel="noopener noreferrer">*/}
          {/*                        <img*/}
          {/*                            src={sponsor.sponsorLogo}*/}
          {/*                            className="sponsor-image"*/}
          {/*                            key={sponsor.id}*/}
          {/*                        />*/}
          {/*                        </a> ))}*/}
          {/*                </div>*/}

          {/*              </div>*/}
          {/*          )}*/}
          {/*          {conference.sponsors.some(sponsor => sponsor.sponsorType === 2) && (*/}
          {/*              <div className="price-sponsors-container custom-sponsors">*/}
          {/*                <h3 className="price-sponsors">*/}
          {/*                  {conference.sponsors.some(sponsor => sponsor.sponsorType === 2) ? (*/}
          {/*                      conference.sponsors.filter(sponsor => sponsor.sponsorType === 2).length > 1 ? (*/}
          {/*                          'Gold Sponsors'*/}
          {/*                      ) : (*/}
          {/*                          'Gold Sponsor'*/}
          {/*                      )*/}
          {/*                  ) : null}*/}
          {/*                </h3>*/}
          {/*                <div className="line-container">*/}
          {/*                  <span className="line-about"></span>*/}
          {/*                </div>*/}
          {/*                <div className="sponsors-wrapper">*/}
          {/*                  <div className="sponsors-image">*/}
          {/*                  {conference.sponsors*/}
          {/*                      .filter(sponsor => sponsor.sponsorType === 2)*/}
          {/*                      .map((sponsor, index) => (*/}
          {/*                        <a href={sponsor.sponsorUrlLogo} key={sponsor.id} target="_blank" rel="noopener noreferrer">*/}
          {/*                          <img*/}
          {/*                              src={sponsor.sponsorLogo}*/}
          {/*                              className="sponsor-image"*/}
          {/*                              key={sponsor.id}*/}
          {/*                          />*/}
          {/*                          </a>))}*/}
          {/*                  </div>*/}
          {/*                </div>*/}
          {/*              </div>*/}
          {/*          )}*/}
          {/*          {conference.sponsors.some(sponsor => sponsor.sponsorType === 3) && (*/}
          {/*              <div className="price-sponsors-container custom-sponsors">*/}
          {/*                <div className="price-sponsors-container">*/}
          {/*                  <h3 className="price-sponsors">*/}
          {/*                    {conference.sponsors.some(sponsor => sponsor.sponsorType === 3) ? (*/}
          {/*                        conference.sponsors.filter(sponsor => sponsor.sponsorType === 3).length > 1 ? (*/}
          {/*                            'Silver Sponsors'*/}
          {/*                        ) : (*/}
          {/*                            'Silver Sponsor'*/}
          {/*                        )*/}
          {/*                    ) : null}*/}
          {/*                  </h3>*/}
          {/*                  <div className="line-container">*/}
          {/*                    <span className="line-about"></span>*/}
          {/*                  </div>*/}
          {/*                  <div className="sponsors-image">*/}
          {/*                  {conference.sponsors*/}
          {/*                      .filter(sponsor => sponsor.sponsorType === 3)*/}
          {/*                      .map((sponsor, index) => (*/}
          {/*                        <a href={sponsor.sponsorUrlLogo} key={sponsor.id} target="_blank" rel="noopener noreferrer">*/}
          {/*                          <img*/}
          {/*                              key={sponsor.id}*/}
          {/*                              src={sponsor.sponsorLogo}*/}
          {/*                              className="sponsor-image"*/}
          {/*                          />*/}
          {/*                          </a>))}*/}
          {/*                  </div>*/}
          {/*                </div>*/}
          {/*              </div>*/}
          {/*          )}*/}
          {/*          {conference.sponsors.some(sponsor => sponsor.sponsorType === 6) && (*/}
          {/*              <div className="price-sponsors-container custom-sponsors">*/}
          {/*                <div className="price-sponsors-container">*/}
          {/*                  <h3 className="price-sponsors">*/}
          {/*                    {conference.sponsors.some(sponsor => sponsor.sponsorType === 6) ? (*/}
          {/*                        conference.sponsors.filter(sponsor => sponsor.sponsorType === 6).length > 1 ? (*/}
          {/*                            'Online Silver Sponsors'*/}
          {/*                        ) : (*/}
          {/*                            'Online Silver Sponsor'*/}
          {/*                        )*/}
          {/*                    ) : null}*/}
          {/*                  </h3>*/}
          {/*                  <div className="line-container">*/}
          {/*                    <span className="line-about"></span>*/}
          {/*                  </div>*/}
          {/*                  <div className="sponsors-image">*/}
          {/*                    {conference.sponsors*/}
          {/*                        .filter(sponsor => sponsor.sponsorType === 6)*/}
          {/*                        .map((sponsor, index) => (*/}
          {/*                            <a href={sponsor.sponsorUrlLogo} key={sponsor.id} target="_blank" rel="noopener noreferrer">*/}
          {/*                              <img*/}
          {/*                                  key={sponsor.id}*/}
          {/*                                  src={sponsor.sponsorLogo}*/}
          {/*                                  className="sponsor-image"*/}
          {/*                              />*/}
          {/*                            </a>))}*/}
          {/*                  </div>*/}
          {/*                </div>*/}
          {/*              </div>*/}
          {/*          )}*/}
          {/*          {conference.sponsors.some(sponsor => sponsor.sponsorType === 4) && (*/}
          {/*              <div className="price-sponsors-container custom-sponsors">*/}
          {/*                <div className="price-sponsors-container">*/}
          {/*                  <h3 className="price-sponsors">*/}
          {/*                    {conference.sponsors.some(sponsor => sponsor.sponsorType === 4) ? (*/}
          {/*                        conference.sponsors.filter(sponsor => sponsor.sponsorType === 4).length > 1 ? (*/}
          {/*                            'Keynote Sponsors'*/}
          {/*                        ) : (*/}
          {/*                            'Keynote Sponsor'*/}
          {/*                        )*/}
          {/*                    ) : null}*/}
          {/*                  </h3>*/}
          {/*                  <div className="line-container">*/}
          {/*                    <span className="line-about"></span>*/}
          {/*                  </div>*/}
          {/*                  <div className="sponsors-image">*/}
          {/*                  {conference.sponsors*/}
          {/*                      .filter(sponsor => sponsor.sponsorType === 4)*/}
          {/*                      .map((sponsor, index) => (*/}
          {/*                        <a href={sponsor.sponsorUrlLogo} key={sponsor.id} target="_blank" rel="noopener noreferrer">*/}
          {/*                          <img*/}
          {/*                              key={sponsor.id}*/}
          {/*                              src={sponsor.sponsorLogo}*/}
          {/*                              className="sponsor-image"*/}
          {/*                          />*/}
          {/*                          </a>))}*/}
          {/*                  </div>*/}
          {/*                </div>*/}
          {/*              </div>*/}
          {/*          )}*/}
          {/*          {conference.sponsors.some(sponsor => sponsor.sponsorType === 5) && (*/}
          {/*              <div className="price-sponsors-container custom-sponsors">*/}
          {/*                <div className="price-sponsors-container">*/}
          {/*                  <h3 className="price-sponsors">*/}
          {/*                    {conference.sponsors.some(sponsor => sponsor.sponsorType === 5) ? (*/}
          {/*                        conference.sponsors.filter(sponsor => sponsor.sponsorType === 5).length > 1 ? (*/}
          {/*                            'Exhibition Sponsors'*/}
          {/*                        ) : (*/}
          {/*                            'Exhibition Sponsor'*/}
          {/*                        )*/}
          {/*                    ) : null}*/}
          {/*                  </h3>*/}
          {/*                  <div className="line-container">*/}
          {/*                    <span className="line-about"></span>*/}
          {/*                  </div>*/}
          {/*                  <div className="sponsors-image">*/}
          {/*                  {conference.sponsors*/}
          {/*                      .filter(sponsor => sponsor.sponsorType === 5)*/}
          {/*                      .map((sponsor, index) => (*/}
          {/*                          <a href={sponsor.sponsorUrlLogo} key={sponsor.id} target="_blank" rel="noopener noreferrer">*/}
          {/*                            <img*/}
          {/*                                key={sponsor.id}*/}
          {/*                                src={sponsor.sponsorLogo}*/}
          {/*                                className="sponsor-image"*/}
          {/*                            />*/}
          {/*                          </a>))}*/}
          {/*                  </div>*/}
          {/*                </div>*/}
          {/*              </div>*/}
          {/*          )}*/}
          {/*        </div>*/}
          {/*      </div>*/}
          {/*    </section>*/}
          {/*)}*/}

          {conference.partners && conference.partners.length > 0 && (
              <section name="partners" className="partners-block">
                <div className="container">
                  <h2
                      className="title-about title-about-single"
                  >
                    Our partners
                  </h2>
                  <div className="line-container">
                    <span className="line-about"></span>
                  </div>

                  <Swiper
                      id="partnersSwiper"
                      ref={(swiper) => (swiperRef.current = swiper)}
                      spaceBetween={10}
                      centeredSlides={true}
                      className="partners-list"
                      navigation={{
                        nextEl: '.right-arrow-partners',
                        prevEl: '.left-arrow-partners',
                      }}
                      pagination={{ clickable: true }}
                      breakpoints={{
                        319: {
                          slidesPerView: 3,
                          centeredSlides: true,
                          initialSlide: 1
                        },
                        767: {
                          slidesPerView: 3,
                          centeredSlides: true,
                          spaceBetween: 5,
                          initialSlide: 1
                        },
                        1023: {
                          slidesPerView: 5,
                          centeredSlides: true,
                          initialSlide: 1
                        }

                      }}

                      onSlideChange={(swiper) => {
                        setActiveSlide(swiper.activeIndex);
                      }}>
                    {conference.partners?.map((partner, index) => (
                        <React.Fragment key={partner.id}>
                          <SwiperSlide onClick={() => handleImageClickpartner(index)}>
                            <a href={getPartnerUrl(partner, conference.eventName)} target="_blank" rel="noopener noreferrer">
                              <li className="your-existing-li-class" style={{ opacity: activeSlide === index ? 1 : 0.5 }}>
                                <img src={partner.partnerLogo} alt={partner.partnerName}/>
                              </li>
                            </a>
                          </SwiperSlide>
                        </React.Fragment>
                    ))}

                  </Swiper>
                  <div className="arrow-container home-arrow-container single-arrow-container">
                    {/*<div className="right-arrow-partners" id="btnNext2"></div>*/}
                    {/*<div className="left-arrow-partners" id="btnPrev2"></div>*/}
                  </div>
                </div>
              </section>
          )}
          {conference.hotels && conference.hotels.length > 0 && (
              <section className="venue-section" id="venue">
                <div className="container" style={{ marginBottom: '25px' }}>
                  <h2 className="title-gallery">Venue</h2>
                  <div className="line-container">
                    <span className="line-about"></span>
                  </div>
                  {conference?.hotels?.map((hotel) => (
                      <div key={hotel.id}>
                      <span>
                        <h3 className="venue-title">
                          {hotel.hotelName}
                          <br/>
                          {myEventDescription.includes(conference.eventName) && (
                              <span style={{ fontSize: 'medium', color: 'lightblue' }}>
                             Book with us and get a discounted price. Contact us at: {" "}
                                <a href={`mailto: general@bcf-events.com`} style={{color: "white", textDecoration: "underline" }}>
                                  general@bcf-events.com
                                      </a>
                              </span>
                          )}
                          </h3>
                         <p className="venue-description">
                          <span className="address-line">{hotel.hotelAddress}</span>
                           {/*<span className="blue-line"></span>*/}
                           {/*     <button onClick={() => window.open(conference.hotelMap, '_blank')} */}
                           {/*             target="_blank" className="how-to-get-button">How to get</button>*/}
                         </p>
                      </span>
                        {conference.hotelBookingUrl && conference.hotelBookingUrl.length > 0  && (
                            <div className="btn-wrapper">
                              <button onClick={() => window.open(conference.hotelBookingUrl, '_blank')} target="_blank" className="venue-btn">Book a room</button>
                            </div>)}
                      </div>))}
                  <span className="opacity-gallery">124</span>
                </div>
                <Swiper
                    id="hotelSwiper"
                    ref={(swiper) => (swiperRef.current = swiper)}
                    initialSlide={3}
                    spaceBetween={10}
                    centeredSlides={true}
                    navigation={{
                      nextEl: '.right-arrow-venue',
                      prevEl: '.left-arrow-venue',
                    }}
                    pagination={{ clickable: true }}
                    breakpoints={{
                      319: {
                        slidesPerView: 3
                      },
                      767: {
                        slidesPerView: 5
                      }
                    }}
                >
                  {hotelImages.map((image, index) => (
                      <React.Fragment>
                        <SwiperSlide key={image} onClick={() => handleImageClickhotel(index)}>
                          <div
                              className="slide-swiper"
                              key={image}
                              style={{
                                backgroundImage: `url(${image})`,
                              }}
                          ></div>
                        </SwiperSlide>
                      </React.Fragment>
                  ))}
                </Swiper>
                <div className="arrow-container home-arrow-container single-arrow-container">
                  {/*<div className="left-arrow-venue" id="btnPrev"></div>*/}
                  {/*<div className="right-arrow-venue" id="btnNext"></div>*/}
                </div>
              </section>
          )}
          {conference.employees && conference.employees.length > 0 && (
              <section name="employees" className="employees-block" id="contact">
                <div className="container">
                  <h2
                      className="title-about title-about-single"
                  >
                    Contact us
                  </h2>
                  <div className="line-container">
                    <span className="line-about"></span>

                  </div>
                  <div>
                    <ul className="contact-list">
                      {conference.employees?.map((employee) => (
                          <li
                              key={employee.id}
                          >
                            <h2>{employee.callToAction}</h2>
                            <img width="150px" src={employee.photo} alt="" />
                            <h3>{employee.fullName}</h3>
                            <h4>{employee.position}</h4>
                            <p className="phone">{employee.phone}</p>
                            <p className="email"><a  className="employee-mailto" href={`mailto:${employee.email}`}>{employee.email}</a></p>
                          </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </section>
          )}
          <section className="tw-overflow-x-hidden">
            <div className="tw-w-full tw-bg-white tw-py-4 sm:tw-py-12 tw-px-0 sm:tw-px-6 md:tw-px-8 lg:tw-px-12 xl:tw-px-16 2xl:tw-px-20">
              <div className="tw-w-[300px] sm:tw-container sm:tw-max-w-6xl tw-mx-auto">
                <div className="tw-grid tw-grid-cols-1 sm:tw-grid-cols-1 md:tw-grid-cols-2 lg:tw-grid-cols-2 xl:tw-grid-cols-2 2xl:tw-grid-cols-2 tw-gap-3 sm:tw-gap-8 tw-items-center">
                  {/* Левая колонка - Текстовое содержимое */}
                  <div className="tw-space-y-2 sm:tw-space-y-6 tw-px-2 sm:tw-px-0">
                    <h2 className="tw-text-lg sm:tw-text-3xl md:tw-text-4xl lg:tw-text-4xl xl:tw-text-5xl tw-font-bold tw-text-[#313F50] tw-break-words">
                      2025 SPONSORSHIP NOW AVAILABLE!
                    </h2>
                    <p className="tw-text-xs sm:tw-text-base md:tw-text-lg lg:tw-text-xl tw-text-gray-700">
                      We partner with a small number of companies at our events to provide
                      maximum value to both sponsors and attendees. 93% of our sponsors
                      rebook each year – but there&apos;s always an opportunity for innovative
                      companies to work with us.
                    </p>
                    <div className="tw-pt-2 sm:tw-pt-4">
                      <Link
                          to="/sponsorship"
                          className="tw-bg-[#D85121] tw-text-white tw-text-[10px] sm:tw-text-base tw-px-2 sm:tw-px-6 md:tw-px-8 lg:tw-px-10 tw-py-1.5 sm:tw-py-3 tw-rounded-full tw-font-semibold hover:tw-bg-[#C74120] tw-transition-colors tw-outline-none tw-border-0 tw-inline-block tw-text-center"
                      >
                        SPONSOR US
                      </Link>
                    </div>
                  </div>

                  {/* Правая колонка - Изображение */}
                  <div className="tw-relative tw-w-[240px] sm:tw-w-full tw-mx-auto">
                    <img
                        src={require("../../assets/images/sponsor-block.png")}
                        alt="Conference networking scene"
                        className="tw-w-full tw-aspect-[16/9] tw-object-cover tw-rounded-lg"
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div className="tw-block tw-max-w-6xl tw-mx-auto tw-px-4 tw-my-12 tw-sm:tw-hidden">
              <h2 className="tw-text-xl tw-md:tw-text-2xl tw-lg:tw-text-3xl tw-font-bold tw-text-center tw-mb-8">
                Previous Attendees Include
              </h2>
              <div className="tw-w-full">
                <img
                    src={require("../../assets/images/all-37.png")}
                    alt="Logos of previous attendees including major companies"
                    className="tw-w-full tw-h-auto"
                />
              </div>
            </div>
          </section>

        </main>
        <Footer />

      </div>
  );
}
//ещё
export default SingleConference;