import React, { useState } from 'react';
import axios from 'axios';
import { NotificationManager } from 'react-notifications';

const UsefulBlock = () => {
    // Состояние для данных формы рассылки
    const [isLoading, setIsLoading] = useState(false);
    const [newsletterData, setNewsletterData] = useState({
        from: 'newsletter',
        firstName: '',
        lastName: '',
        email: '',
    });

    // Обработчик изменения полей формы
    const handleNewsletterChange = (e) => {
        const { name, value } = e.target;
        setNewsletterData(prevData => ({
            ...prevData,
            [name]: value,
        }));
    };

    // Обработчик отправки формы
    const handleNewsletterSubmit = async (e) => {
        e.preventDefault();

        let url = '';
        if (window.location.hostname === 'localhost') {
            url = 'https://api.bcf-events.com/';
        }
        if ([
            'green-desert-021865b10.3.azurestaticapps.net',
            'brave-dune-00ae5aa10.3.azurestaticapps.net',
            'new.bcf-events.com',
            'bcf-events.com'
        ].includes(window.location.hostname)) {
            url = 'https://api.bcf-events.com/';
        }

        try {
            setIsLoading(true);

            // Формируем объект с нужными полями:
            const data = {
                from: newsletterData.from,
                name: `${newsletterData.firstName} ${newsletterData.lastName}`,
                email: newsletterData.email,
            };

            const response = await axios.post(`${url}User/slack/newsletter`, data);
            console.log('Response from endpoint:', response);
            NotificationManager.success('Thank you for subscribing!', 'Success', 5000);

            // Сброс формы
            setNewsletterData({
                from: 'newsletter',
                firstName: '',
                lastName: '',
                email: '',
            });
        } catch (error) {
            console.error('Error sending message to Slack:', error);
            NotificationManager.error('Error sending message', 'Error', 5000);
        } finally {
            setIsLoading(false);
        }
    };


    return (
        <section className="useful-block">
            {/* Верхняя обёртка: чтобы отцентрировать контент */}
            <div className="useful-block-top-inner">
                {/* TOP: ссылки слева, форма справа */}
                <div className="useful-block-top">
                    {/* Левая колонка: LINKS */}
                    <div className="links-column">
                        <h3>USEFUL LINKS</h3>
                        <ul>
                            <li><a href="/membershipform">MEMBERSHIP</a></li>
                            <li><a href="/about">ABOUT US</a></li>
                            <li><a href="/sponsorship">SPONSORSHIP</a></li>
                            <li><a href="/conferences">B2B CONFERENCES</a></li>
                            <li><a href="/faq">FAQs</a></li>
                            <li><a href="/gallery">GALLERY</a></li>
                            <li><a href="/events">PAST EVENTS</a></li>
                            <li><a href="/contacts">CONTACT US</a></li>
                        </ul>
                    </div>

                    {/* Правая колонка: NEWSLETTER (форма 2×2) */}
                    <div className="newsletter-column">
                        <h3>JOIN OUR NEWSLETTER</h3>
                        <p>Stay informed about our latest events.</p>
                        <form className="newsletter-form" onSubmit={handleNewsletterSubmit}>
                            <input
                                type="text"
                                name="firstName"
                                placeholder="Name"
                                value={newsletterData.firstName}
                                onChange={handleNewsletterChange}
                                required
                            />
                            <input
                                type="text"
                                name="lastName"
                                placeholder="Last Name"
                                value={newsletterData.lastName}
                                onChange={handleNewsletterChange}
                                required
                            />
                            <input
                                type="email"
                                name="email"
                                placeholder="Email"
                                value={newsletterData.email}
                                onChange={handleNewsletterChange}
                                required
                            />
                            <button type="submit" disabled={isLoading}>
                                {isLoading ? "Sending..." : "Send"}
                            </button>
                        </form>
                    </div>
                </div>
            </div>

            {/* НИЖНЯЯ ЧАСТЬ (контакты + соцсети), "прибитая" к низу */}
            <footer className="footer">
                <div className="footer-inner">
                    {/* Три колонки с контактами */}
                    <div className="footer-contacts">
                        <div className="footer-col">
                            <h4>LOCATION</h4>
                            <p>Hartigova 2660/141, 130 00 Prague - Žižkov</p>
                        </div>

                        <div className="footer-col">
                            <h4>CONTACT | GENERAL INQUIRIES</h4>
                            <p>
                                <a href="mailto:general@bcf-events.com">general@bcf-events.com</a>
                            </p>

                            <h4>MEDIA PARTNERSHIP & PROMOTION</h4>
                            <p>
                                <a href="mailto:artyom.davydov@bcfgroup.eu">artyom.davydov@bcfgroup.eu</a>
                            </p>
                        </div>

                        <div className="footer-col">
                            <h4>PHONE</h4>
                            <p>General: +420 214 214 720</p>
                            <p>Marketing: +420 228 227 162</p>
                            <p>Production: +420 228 227 163</p>
                            <p>Operations: +420 228 227 164</p>
                        </div>
                    </div>

                    {/* Соцсети */}
                    <div className="footer-social">
                        <a href="https://twitter.com/BCFgroupeu" target="_blank" rel="noopener noreferrer">X</a>
                        <a href="https://www.youtube.com/@bcfgroup5618/videos" target="_blank" rel="noopener noreferrer">YouTube</a>
                        <a href="https://www.facebook.com/bcfgroup.eu" target="_blank" rel="noopener noreferrer">Facebook</a>
                        <a href="https://www.instagram.com/bcfgroupeu/" target="_blank" rel="noopener noreferrer">Instagram</a>
                        <a href="mailto:general@bcf-events.com" target="_blank" rel="noopener noreferrer">Email</a>
                        <a href="https://www.linkedin.com/company/business-conference-facilitation/" target="_blank" rel="noopener noreferrer">LinkedIn</a>
                    </div>
                </div>
            </footer>
        </section>
    );
};

export default UsefulBlock;
