import React, { useState, useEffect } from 'react';
import { X, Mail } from 'lucide-react';
import { useLocation } from 'react-router-dom';

const DiscountBanner = ({ handleOpenModal }) => {
    const [isVisible, setIsVisible] = useState(false);
    const location = useLocation();

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsVisible(true);
        }, 2000);

        return () => clearTimeout(timer);
    }, []);

    const handleOpenSpecialModal = () => {
        handleOpenModal("agenda", "discount_banner");
        setIsVisible(false);
    };

    if (!isVisible) return null;

    return (
        <div className="tw-fixed tw-bottom-0 tw-left-0 tw-w-full tw-bg-orange-500 tw-text-white tw-py-1.5 tw-z-50">
            <div className="tw-container tw-mx-auto tw-px-4">
                <div className="tw-flex tw-items-center tw-justify-center tw-gap-4">
                    {/* Discount badge with icon */}
                    <div className="tw-flex tw-items-center tw-gap-1.5">
                        <Mail className="tw-w-3.5 tw-h-3.5" />
                        <span className="tw-bg-white tw-text-orange-500 tw-px-2 tw-py-0.5 tw-rounded tw-font-bold tw-whitespace-nowrap tw-text-xs">
                            Get an Exclusive 20%+ Discount!
                        </span>
                    </div>

                    {/* Description text */}
                    <p className="tw-text-xs tw-max-w-2xl">
                        Leave your contact details, and our manager will reach out to you shortly with a special offer! Don't miss out—secure your discount today!
                    </p>

                    {/* Buttons section */}
                    <div className="tw-flex tw-items-center tw-gap-2 tw-shrink-0">
                        <button
                            onClick={handleOpenSpecialModal}
                            className="tw-bg-white tw-text-orange-500 hover:tw-bg-orange-50 tw-px-3 tw-py-1 tw-rounded tw-transition-colors tw-font-medium tw-whitespace-nowrap tw-text-xs"
                        >
                            Get an Offer
                        </button>
                        <button
                            onClick={() => setIsVisible(false)}
                            className="tw-p-0.5 hover:tw-bg-orange-400 tw-rounded-full tw-transition-colors"
                        >
                            <X className="tw-w-4 tw-h-4" />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DiscountBanner;