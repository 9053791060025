import React, { useState, useEffect } from 'react';
import { ChevronLeft, ChevronRight, Gift, Users, Briefcase } from 'lucide-react';
import axios from 'axios';
import { NotificationManager } from 'react-notifications';
import { ThreeDots } from 'react-loader-spinner';
import Header from "../../components/website/Header";

const MembershipForm = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState({
        from: "membership form",
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        company: '',
        needs: '',
    });

    const [currentSlide, setCurrentSlide] = useState(0);
    const slides = [
        {
            title: 'Enjoy Great Discounts and Exclusive Benefits',
            content: [
                'Exclusive Discounts: Enjoy discounts of 20% to 50% on our products.',
                'Complimentary Accommodation: All participants receive 2 nights of free accommodation at the conference hotel.',
                'Workshop & Seminar Sponsorship Discounts: Special rates for sponsoring workshops and seminars.'
            ],
            icon: <Gift size={28} className="tw-text-gray-600" />
        },
        {
            title: 'Networking Opportunities',
            content: [
                'Join us for a pre-conference networking event with drinks and snacks to connect with peers before the main event.',
                'Receive a comprehensive list of attendees, including their emails, to help you follow up after the event.'
            ],
            icon: <Users size={28} className="tw-text-gray-600" />
        },
        {
            title: 'Membership Details',
            content: [
                'Duration: Membership is valid for one calendar year.',
                'Cost: Less than €50 per year.',
                'Once your payment is processed, you will gain access to all the exclusive benefits and discounts that BCF offers.'
            ],
            icon: <Briefcase size={28} className="tw-text-gray-600" />
        }
    ];

    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentSlide((prev) => (prev + 1) % slides.length);
        }, 5000);
        return () => clearInterval(timer);
    }, [slides.length]);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        let url = '';

        if (window.location.hostname === 'localhost') {
            url = 'https://api.bcf-events.com/';
        }
        if ([
            'green-desert-021865b10.3.azurestaticapps.net',
            'brave-dune-00ae5aa10.3.azurestaticapps.net',
            'new.bcf-events.com',
            'bcf-events.com'
        ].includes(window.location.hostname)) {
            url = 'https://api.bcf-events.com/';
        }

        try {
            setIsLoading(true);
            const data = {
                ...formData,
                name: `${formData.firstName} ${formData.lastName}`,
                message: formData.needs
            };
            const response = await axios.post(`${url}User/slack/membership`, data);
            console.log('response from endpoint:', response);
            NotificationManager.success('Thank you for your request!', 'Success', 5000);

            setTimeout(() => {
                window.location.reload(false);
            }, 3000);
        } catch (error) {
            console.error("Error sending message to Slack:", error);
            NotificationManager.error('Error sending message', 'Error', 5000);
        } finally {
            setIsLoading(false);
        }
    };

    const nextSlide = () => setCurrentSlide((prev) => (prev + 1) % slides.length);
    const prevSlide = () => setCurrentSlide((prev) => (prev - 1 + slides.length) % slides.length);

    return (
        <div>
            <div className="tw-bg-[#1a1a1a] tw-text-white tw-pb-10">
                <Header />
                <div className="tw-flex tw-justify-center tw-items-center tw-py-12 tw-px-4">
                    <h1 className="tw-text-3xl md:tw-text-4xl tw-font-bold tw-text-center">Membership &amp; Benefits</h1>
                </div>
            </div>

            <div className="tw-bg-[#f5f5f5]">
                <div className="tw-flex tw-flex-col lg:tw-flex-row tw-p-4 md:tw-p-6 tw-max-w-6xl tw-mx-auto tw-gap-8">
                    {/* Левая колонка со слайдами */}
                    <div className="tw-w-full lg:tw-w-1/2">
                        <div className="tw-text-blue-600 tw-mb-2 tw-text-center lg:tw-text-left">CONTACT BCF NOW</div>
                        <h2 className="tw-text-2xl md:tw-text-3xl tw-font-bold tw-text-[#1a1a1a] tw-mb-4 tw-text-center lg:tw-text-left">
                            How to become a member
                        </h2>

                        <div className="tw-relative tw-min-h-[24rem] tw-border tw-border-gray-200 tw-rounded-lg tw-bg-white tw-p-4 md:tw-p-6 tw-shadow-sm">
                            <div className="tw-space-y-3 tw-text-center">
                                <div className="tw-flex tw-items-center tw-justify-center tw-gap-3 tw-flex-wrap">
                                    {slides[currentSlide].icon}
                                    <h3 className="tw-text-lg md:tw-text-xl tw-font-medium tw-text-[#1a1a1a]">
                                        {slides[currentSlide].title}
                                    </h3>
                                </div>
                                <div className="tw-text-gray-600 tw-space-y-2">
                                    {slides[currentSlide].content.map((item, index) => (
                                        <p key={index} className="tw-leading-relaxed tw-text-sm md:tw-text-base">{item}</p>
                                    ))}
                                </div>
                            </div>

                            <div className="tw-absolute tw-bottom-3 tw-left-0 tw-right-0 tw-flex tw-justify-center tw-items-center tw-gap-4">
                                <button
                                    onClick={prevSlide}
                                    className="tw-p-1.5 tw-rounded-full tw-bg-gray-100 hover:tw-bg-gray-200 tw-transition-colors"
                                >
                                    <ChevronLeft size={18} />
                                </button>

                                <div className="tw-flex tw-gap-1.5">
                                    {slides.map((_, index) => (
                                        <button
                                            key={index}
                                            onClick={() => setCurrentSlide(index)}
                                            className={`tw-w-2.5 tw-h-2.5 tw-rounded-full tw-transition-colors ${
                                                currentSlide === index ? 'tw-bg-blue-600' : 'tw-bg-gray-300'
                                            }`}
                                        />
                                    ))}
                                </div>

                                <button
                                    onClick={nextSlide}
                                    className="tw-p-1.5 tw-rounded-full tw-bg-gray-100 hover:tw-bg-gray-200 tw-transition-colors"
                                >
                                    <ChevronRight size={18} />
                                </button>
                            </div>
                        </div>
                    </div>

                    {/* Правая колонка с формой */}
                    <div className="tw-w-full lg:tw-w-1/2">
                        <form onSubmit={handleSubmit} className="tw-w-full tw-max-w-lg tw-mx-auto tw-space-y-6">
                            <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-4 md:tw-gap-6">
                                <div>
                                    <label className="tw-block tw-text-[#1a1a1a] tw-mb-1.5">
                                        First Name*
                                    </label>
                                    <input
                                        type="text"
                                        name="firstName"
                                        required
                                        className="tw-w-full tw-px-4 tw-py-2 tw-border tw-border-gray-300 tw-bg-white tw-rounded"
                                        value={formData.firstName}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div>
                                    <label className="tw-block tw-text-[#1a1a1a] tw-mb-1.5">
                                        Last Name*
                                    </label>
                                    <input
                                        type="text"
                                        name="lastName"
                                        required
                                        className="tw-w-full tw-px-4 tw-py-2 tw-border tw-border-gray-300 tw-bg-white tw-rounded"
                                        value={formData.lastName}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>

                            <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-4 md:tw-gap-6">
                                <div>
                                    <label className="tw-block tw-text-[#1a1a1a] tw-mb-1.5">
                                        Email*
                                    </label>
                                    <input
                                        type="email"
                                        name="email"
                                        required
                                        className="tw-w-full tw-px-4 tw-py-2 tw-border tw-border-gray-300 tw-bg-white tw-rounded"
                                        value={formData.email}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div>
                                    <label className="tw-block tw-text-[#1a1a1a] tw-mb-1.5">
                                        Phone Number*
                                    </label>
                                    <input
                                        type="tel"
                                        name="phoneNumber"
                                        required
                                        className="tw-w-full tw-px-4 tw-py-2 tw-border tw-border-gray-300 tw-bg-white tw-rounded"
                                        value={formData.phoneNumber}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>

                            <div>
                                <label className="tw-block tw-text-[#1a1a1a] tw-mb-1.5">
                                    Company*
                                </label>
                                <input
                                    type="text"
                                    name="company"
                                    required
                                    className="tw-w-full tw-px-4 tw-py-2 tw-border tw-border-gray-300 tw-bg-white tw-rounded"
                                    value={formData.company}
                                    onChange={handleChange}
                                />
                            </div>

                            <div>
                                <label className="tw-block tw-text-[#1a1a1a] tw-mb-1.5">
                                    Tell us what you need*
                                </label>
                                <textarea
                                    name="needs"
                                    required
                                    rows={5}
                                    className="tw-w-full tw-px-4 tw-py-2 tw-border tw-border-gray-300 tw-bg-white tw-rounded"
                                    value={formData.needs}
                                    onChange={handleChange}
                                />
                            </div>

                            <div className="tw-space-y-2">
                                <div className="tw-flex">
                                    <input
                                        type="checkbox"
                                        name="acceptCommunications"
                                        required
                                        className="tw-mt-1 tw-mr-2"
                                        checked={formData.acceptCommunications}
                                        onChange={handleChange}
                                    />
                                    <label className="tw-text-gray-600 tw-text-sm">
                                        I agree to receive communications from BCF.*
                                    </label>
                                </div>
                                <div className="tw-flex">
                                    <input
                                        type="checkbox"
                                        name="acceptPrivacyPolicy"
                                        required
                                        className="tw-mt-1 tw-mr-2"
                                        checked={formData.acceptPrivacyPolicy}
                                        onChange={handleChange}
                                    />
                                    <label className="tw-text-gray-600 tw-text-sm">
                                        I have read and accept{" "}
                                        <a href="#" className="tw-text-[#ff6b00]">
                                            Privacy Policy
                                        </a>
                                        *
                                    </label>
                                </div>
                            </div>

                            <button
                                type="submit"
                                className="tw-w-full md:tw-w-48 tw-bg-[#ffb800] tw-text-black tw-py-2 tw-font-medium tw-mx-auto tw-block tw-flex tw-justify-center tw-items-center tw-rounded"
                                disabled={isLoading}
                            >
                                {isLoading ? (
                                    <ThreeDots height="24" width="24" color="black" />
                                ) : (
                                    "SUBMIT"
                                )}
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MembershipForm;